import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CustomersFormsService } from 'src/app/services/customers-forms.service';
import { MeetingService } from 'src/app/services/meeting.service';
import { RespondentFormsService } from 'src/app/services/respondent-forms.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-create-surgiai-patient',
  templateUrl: './create-surgiai-patient.component.html',
  styleUrls: ['./create-surgiai-patient.component.css']
})
export class CreateSurgiaiPatientComponent implements OnInit {

  public clinicHistoryText:string;
  public authForm: any;
  public formToSendData: any;
  public customerForm: any;
  public IdUserCreate;
  public customerData : any;
  public dataSesion;
  public forms;
  public formsCardiology;
  public selected;
  public title_text ;
  public channel;
  public clinicsData:any;
  public msgDone = false;
  public mailSender:any;
  public patientMail:any;
  public userDataNcrypt;
  public mailUserSender;
  public disabledBTN = false;
  public idStablihsmntCardiology;
  public respondentIDByConsult;
  public aditionalForm;
  public btnCreate = true;
  public frame = 0;
  public createFormFrame = 0;
  public idUser;
  public generalBoolBTN = true;
  
  patName : String = '';
  patLastName : String = '';
  
  periodic: string = 'normal'
  filterFormByIdStablisment: any[] = [];

  constructor(
    public authService: AuthService,
    public router: Router,
    public meetingService:MeetingService,
    private customerService: CustomersFormsService,
    public RespondentFormsSrv: RespondentFormsService,
    private userService: UsersService,
    private spinnerService: SpinnerService
  ) {
    this.userService.setOnCreatePatient(true);
  
    this.authForm = {
      usr_id: null,
      doc_id: null,
      google_id: null,
      phone: '',
      code: 0,
      name: null,
      password: null,
      email: null,
      role: 'respondent',
      provider: 'default',
      status: 'default',
      created_on: null,
      intPhoneCodes: "593",
      id_customer: null,
      id_stablishment:null,
      clinic_history:null    
    };

    this.formToSendData= {
      nameForm: null,//'hola',
      description:  null,//'hola',
      customer_id: null,//2,
      created_on : null,     
      structure: null,//'hola',
      answered_check : false,
      respondents_id: null,//229,
      custf_id: null,//3,
      status: 'onhold',
      specialist: null,
      id_stablishment: null                
    }

    this.mailSender= {
      headerMail:'Registro de nuevo paciente',
      nameDoctor:'Prueba',
      mailDoctor:'sergionexx1@gmail.com',
      text:"mensaje de prueba",
      nameUser:'Sergio',
      passUser:'1234'                
    }

    this.patientMail= {
      headerMail:'Plataforma IPSS - Datos creacion paciente',
      nameDoctor:'Prueba',
      mailDoctor:'',
      text:"mensaje de prueba",
      nameUser:'Sergio',
      passUser:'1234'                
    }
    this.userDataNcrypt = {
      usr_data:'',
      hash:localStorage.getItem('hash_stablishment')
    }
  
   }

  ngOnInit(): void {
    this.msgDone = false;
    console.log('CustomerID**************',localStorage.getItem('customer_id'));

    if(localStorage.getItem('customer_id') == "2"){
      this.channel = 2;
      this.title_text ='Número de Paciente ';  
    }else{
      this.title_text = 'Número de cédula ';    
      this.channel = 1;
    }
    
    console.log('Estrucutura recuperada', JSON.parse(localStorage.getItem('customerData')));
    this.customerData = JSON.parse(localStorage.getItem('customerData'));
    console.log('Date',Date.now())
    this.getDatabyIdCustomer();

    this.dataSesion = this.authService.getDataSesion();
    console.log('dataSession',this.dataSesion);
    
    let clinics = JSON.parse(localStorage.getItem('clinics'));
    let dataToFilter = clinics.clinics;
    this.clinicsData = dataToFilter.filter(element=>element.code== this.dataSesion.id_stablishment);
    console.log('clinicas filter',this.clinicsData);
        
    localStorage.getItem('urlToMail');   
    this.spinnerService.updateSpinnerState(false);
    
  }

  periodsAreValid(): boolean {
    let isValid: boolean = true;

    for(let key in this.customPeriodArray){
      for(let i = 0; i < this.customPeriodArray[key].period.length; i++){
        let period = this.customPeriodArray[key].period[i];
        if(period.amount <= 0){
          isValid = false;
          break;
        }
        if(period.unit == 'days'){
          period.amount *= 24;
          period.unit = 'hours';
        }else if(period.unit == 'months'){
          period.amount *= (24 * 30);
          period.unit = 'hours';
        }
      }
    }

    if(isValid){
      for(let key in this.customPeriodArray){
        this.customPeriodArray[key].period = this.customPeriodArray[key].period.map(value => value.amount.toString());
      }  
    }

    console.log(this.customPeriodArray);

    return isValid;
  }

   verifyUser(){
    if(!this.patName.trim()){
      alert('El número de paciente es obligatorio');
    }else if(!this.periodsAreValid()){
      alert('Debe completar la información de la frecuencia correctamente!');
    }
    else if(this.selected != null && this.selected != ''){
      this.btnCreate  = false;
      console.log('VERIFICAR: ======> ',this.clinicHistoryText);
      this.userService.getPatientByName(this.patName,this.dataSesion.customerId,this.selected).subscribe(res=>{
        console.log('respuesta verifyUser',res);
        if(res.resp.length ==0){
          this.createUSer();
          this.btnCreate = false;
        }else{
          alert('El usuario que ha ingresado ya existe');
          this.btnCreate = true;
        }    
      });
    }else{
      alert('Debe seleccionar un centro médico');
    }

  }     

   authUSer(){
    console.log('respuestaClicked');
    this.authService.auth(this.authForm).subscribe(res=>{
      console.log('respuestaAuth',res);
    });
   }

  createUSer(){
    //filtrar por forms seleccionado
    let formSelect = this.forms;
    
    console.log('FormulariosFiltrados!',formSelect);
    let passRamdom = Math.floor(1000 + Math.random() * 9000);
    this.authForm.doc_id = this.patName.toUpperCase().trim();
    this.authForm.password = passRamdom.toString();
    this.authForm.id_customer = parseInt(localStorage.getItem('customer_id')) ;
    this.authForm.created_on = Date.now();
    this.authForm.id_stablishment = this.selected;
    this.authForm.clinic_history = this.clinicHistoryText;
    //let phoneDistinct = this.authForm.phone.split('').reverse().slice(0,4).reverse().join('');
    
    this.authForm.name = this.patName.toUpperCase().trim();
    
    this.authForm.periodicity_forms = this.filterFormByIdStablisment.map(form => {
      return {
        custf_id: form.custf_id,
        periodicity: form.periodic[this.periodicLevel[this.periodic]]
      }
    });
    
    if(this.periodic == 'customized'){
      for(let i = 0; i < this.authForm.periodicity_forms.length; i++){
        let id = this.authForm.periodicity_forms[i].custf_id;
        console.log(id);
        console.log(this.customPeriodArray);
        if(this.customPeriodArray.hasOwnProperty(`id-${id}`)){
          this.authForm.periodicity_forms[i].periodicity = this.customPeriodArray[`id-${id}`];
        }
      }
    }
    
    this.authForm.periodicity_forms = JSON.stringify(this.authForm.periodicity_forms);

    console.log('salida',this.authForm)
    this.userService.createUser(this.authForm).subscribe(res => {
      
      /** Voy a filtrar los formularios del customer por el id_stablishment del formulario seleccionado*/
      this.IdUserCreate = res.resp.usr_id;// se recupera id de usuario creado
      
      let specialistId = parseInt(localStorage.getItem('id'));
      let createdDate = Date.now();

      let filteredMap = this.filterFormByIdStablisment.map((form: any) => {
        return {
          respondents_id: parseInt(this.IdUserCreate),
          structure: JSON.stringify(form.structure),
          initial_form: form.structure[0].initial_form,
          activation_status: 'I',
          name:  form.name,
          description: form.description,
          customer_id: parseInt(form.customer_id),
          custf_id:  parseInt(form.custf_id),
          created_on: createdDate,
          specialist: specialistId,
          status: 'onhold/onhold',
          id_stablishment: this.dataSesion.id_stablishment,
          periodic: this.getPeriodic(form.custf_id)
        }
      });

      console.log('respuestaCreateUser',filteredMap);

      // this.mailSender.nameDoctor = 
      this.mailSender.nameDoctor = localStorage.getItem('name');
      this.mailSender.mailDoctor = localStorage.getItem('email');
      this.mailSender.nameUser = this.authForm.name;
      this.mailSender.passUser =passRamdom.toString();
      
      this.mailSender.link =  localStorage.getItem('urlToMail');
      this.mailSender.nombrePaciente = this.authForm.name;
      this.mailSender.text = 'Estimad@ '+localStorage.getItem('name') +', se ha ingresado correctamente el paciente: '+ this.authForm.name +', para iniciar sesion vaya al siguiente link '+ localStorage.getItem('urlToMail') + ' y acceda con las credenciales del usuario ingresado. ';
      
      this.disabledBTN=true;
      this.createForms(filteredMap);
      //this.createForm();
      // this.sendMailCredentialsToUser();
      this.sendMailToSpecialist();
      alert('Paciente creado con éxito');
    })//console.log(err)

  }


  getPeriodic(custf_id: number){
    if(this.customPeriodArray.hasOwnProperty('id-'+custf_id)){
      return this.customPeriodArray['id-'+custf_id];
    }
    return this.filterFormByIdStablisment.find(item => item.custf_id == custf_id).periodic[0];
  }


   AditionalForm(){
    this.authService.createForm(this.formToSendData).subscribe(resp => {
      console.log('Se a guardado el formulario con exito', parseInt(resp.resp)  )
    }
    )
   }

   addFormInUser(){
    let formSelect = this.forms.filter(element => element.name == this.idStablihsmntCardiology.name);
   
    this.formToSendData.respondents_id = this.respondentIDByConsult;
    this.formToSendData.structure =  formSelect[0].structure ;
    this.formToSendData.nameForm =  formSelect[0].name;
    this.formToSendData.description =  formSelect[0].description;
    this.formToSendData.customer_id =  formSelect[0].customer_id;
    this.formToSendData.custf_id =  formSelect[0].customer_id;
    this.formToSendData.created_on = Date.now();
    this.formToSendData.specialist = parseInt(localStorage.getItem('id'));    
    this.authService.createForm(this.formToSendData).subscribe(resp => {
      console.log('Se a guardado el formulario con exito', parseInt(resp.resp))
      //llevar a edit-respondent-form/252      
        this.router.navigate(['edit-respondent-form/'+ parseInt(resp.resp)])   
    }); 
  }

  createForms(forms: any[]){
    this.authService.createForm(forms).subscribe(resp => {
      console.log(resp);
      this.router.navigate(['list-specialist-forms']);
    },err => console.log('error al crear el formulario',err)); 
  }
  
  sendMailToSpecialist(){
    this.meetingService.sendMailToDoctor(this.mailSender).subscribe(res=>{
      console.log('sendMailTodoctor',res);
    })
  }

  getDatabyIdCustomer(){
    this.customerService.getByIdCustForm(localStorage.getItem('customer_id')).subscribe(res => { // poner id de canal this.dataSesion.customerId
      console.log('Parse periodic',JSON.parse(res.resp[0].periodic))
      console.log('Customer_forms', res.resp);
      
      res.resp.sort(function(a, b) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        // names must be equal
        return 0;
      });

      if(this.dataSesion.logical_medical=='urology'){
        this.forms = res.resp;
        let filterByUnique = this.forms.filter(element=>element.id_stablishment == '00010');
        console.log('filterByUnique',filterByUnique);
        this.aditionalForm = filterByUnique;
      }else{
        if(this.dataSesion.logical_medical== 'cardiology'){   
          console.log('this.dataSesion.logical_medical', res);      
          let dataFilter = res.resp;
          this.forms = dataFilter.filter(element => element.id_stablishment == '00020' ||  element.id_stablishment == this.dataSesion.id_stablishment);   
          this.formsCardiology = dataFilter.filter(element =>  element.id_stablishment == this.dataSesion.id_stablishment);
          this.aditionalForm = dataFilter.filter(element =>  element.id_stablishment == '00020');
          console.log('getDatabyIdCustomer', this.forms)     

        }else{
          
          let filterByStablishment =  res.resp.filter(element=>element.customer_id == this.dataSesion.customerId);    
          console.log('filterByStablishment',filterByStablishment); 
          if(filterByStablishment.length == 0){          
            let filterByCero =  res.resp.filter(element=> element.id_stablishment == '0' );
            console.log('filterByCero', filterByCero);
            this.forms = filterByCero;
          }else{
            this.forms = filterByStablishment;          
          }       
        }
      }

      this.spinnerService.updateSpinnerState(false);       

    });  
    
  }

  onChange($event){
    console.log('$vent',$event)
  }

  chooseStablishment(data){
    console.log('forms ',this.forms);
    let filtered = this.forms.filter(element=>element.id_stablishment == this.selected);
    // filtered.periodic = JSON.parse(this.periodic);
    this.filterFormByIdStablisment = filtered.map(form => {
      return { 
        custf_id: form.custf_id,
        customer_id: form.customer_id,
        description: form.description,
        form_weight: form.form_weight,
        id_stablishment: form.id_stablishment,
        name: form.name,
        periodic: JSON.parse(form.periodic),
        structure: form.structure
      }
    });
    console.log('chooseStablishment',data,this.filterFormByIdStablisment);
    if(this.periodic == 'customized') this.generateCustomForm();
  }
  
  customPeriodArray: any = {};

  generateCustomForm(){
    this.customPeriodArray = {};
    console.log('generado');
    for(let i = 0; i < this.filterFormByIdStablisment.length; i++){
      let custf_id = this.filterFormByIdStablisment[i].custf_id;
      if(this.filterFormByIdStablisment[i].periodic[0].validatedPeriod){
        let periodic = this.filterFormByIdStablisment[i].periodic[0];
        this.customPeriodArray[`id-${custf_id}`] = {};
        Object.assign(this.customPeriodArray[`id-${custf_id}`],periodic);
        this.customPeriodArray[`id-${custf_id}`].period = this.customPeriodArray[`id-${custf_id}`].period.map(value => ({amount: value, unit: 'hours'}));
        console.log(this.customPeriodArray[`id-${custf_id}`].period);
        // for(let j = 0; j < this.filterFormByIdStablisment[i].periodic[0].period.length; j++){
        // }
      }
    }
    console.log(this.customPeriodArray);
  }

  addPeriod(custf_id: number){
    this.customPeriodArray['id-'+custf_id].period.push(
      {
        amount: 0,
        unit: 'hours'
      }
    );
  }

  deletePeriod(i: number,custf_id: number){
    if(this.customPeriodArray['id-'+custf_id].period.length < 3){
      alert('Necesita establecer al menos 2 periodos en los que se generará el formulario.');
    }else{
      this.customPeriodArray['id-'+custf_id].period.splice(i,1);
      console.log(this.customPeriodArray);
    }
  }

  ngOnDestroy(): void {
    this.userService.setOnCreatePatient(false);
  }

  periodicLevel = {
    normal: 0,
    grave: 1,
    customized: 0
  }

}
