
import { ActivatedRoute, Router } from '@angular/router';
import { RespondentFormsService } from '../../services/respondent-forms.service';
import { RespondentForm } from '../../models//respondent_form.model';
import { off } from 'process';
import { Component, OnInit, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { FomrsService } from '../../services/fomrs.service';
import { element } from 'protractor';
import { ModalAlertComponent } from '../_presenters/modal-alert/modal-alert.component';
import { MatDialog } from '@angular/material/dialog';
import { Console } from 'console';
import { MeetingService } from '../../services/meeting.service';
import { AuthService } from '../../services/auth.service';
import { ModalPdfViewComponent } from '../_presenters/modal-pdf-view/modal-pdf-view.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { globalData } from 'src/app/global/global_data';

@Component({
  selector: 'app-zoom-meeting',
  templateUrl: './zoom-meeting.component.html',
  styleUrls: ['./zoom-meeting.component.css']
})
export class ZoomMeetingComponent implements OnInit {
  private form: any;
  private currentTab: number;
  private tabs: any;
  private id: number;
  public diagnosis: string;
  public pages: any;
  public respondentForm:any;
  public structure: any;
  public segments: string[];
  public mdlMessage: any;
  public titleMessage: string;
  public message: string;
  public select = null;
  public warnin =false ;
  public counter: any;
  public value ;
  public dataConfMeet: any;
  public date;
  public minDate: Date;
  public maxDate: Date;
  public pdfViewer;
  public pre_status;
  public resultWeight = ''
  public comentarios = ''; 
  public dataSesion;
  public urlPDF;
  public  actualPatient: String;
  
 
  @Output() AddRmvAnswered = new EventEmitter();

  @HostListener('change', ['$event'])
  onChange(event: any): void {    
    console.log('eventto',event);
    ////console.log('clickCheckDate', this.date); 
    if(event.target.id=='anamnesis'){
      this.diagnosis = event.target.value
      ////console.log('entro y cambio ngmoel diagnosis')
    }
    if(event.target.value =='Si'){
      //console.log('entro a si')
      this.select='Si'
      this.respondentForm['presumption'] ='Si'
    }
    if(event.target.value =='No'){
      //console.log('entro a No')
      this.select='No'
      this.respondentForm['presumption'] ='No'
    }
    
   this.structure.map(function(dato){
     ////console.log('DatosMapeados',dato)
    for (let id = 0; id < dato.pages.length; id++){
      let data = dato.pages[id] 
      let filterByData = data.filter(element => element.type == 'Number'|| element.type == 'Email' || element.type == 'Text'|| element.type == 'Textarea' ); 
     
      // ////console.log('filterByNumber ', filterByNumber ); 
      var path = event.path || (event.composedPath && event.composedPath());     
      for (let i = 0; i < filterByData.length; i++) {
        if( filterByData[i].name === path[0].name){
          //////console.log('For-answer', filterByRadio[i].answers[0])            
           if(filterByData[i].answers[0] !== event.target.value){
            filterByData[i].answers[0] = event.target.value;
            // ////console.log('Se agrego',event.target.value) 
           }
        }
      }       
      let filterByCheckBox = data.filter(element => element.type == 'Checkbox')
      //  ////console.log('filterByCheckBox ', filterByCheckBox )
       for (let i = 0; i < filterByCheckBox.length; i++) {
        if( filterByCheckBox[i].name === path[0].name){ 
          let found = filterByCheckBox[i].answers.findIndex(element => element == path[0].defaultValue);
          // ////console.log('index que coincide', found);
          if(found == -1 ){
            if(path[0].defaultValue==='Ninguno'|| path[0].defaultValue==='Ninguna' && event.target.checked==true){                 
              filterByCheckBox[i].answers.splice(0, filterByCheckBox[i].answers.length)
              //console.log('Nunguna',filterByCheckBox[i]);              
            }else{
              filterByCheckBox[i].answers.push(path[0].defaultValue)
              //console.log('ValueFilterCheck',filterByCheckBox[i]);
            }                
          }
          else{                
            // ////console.log('ValueFilterCheck',filterByCheckBox[i].answers[found],found);
            let idValue
            if(found=== 0){
              idValue = 0;
            }else{
              idValue = found - 1;
            }
            filterByCheckBox[i].answers.splice(idValue,1) 
            event.target.checked = false               
          }          
        }       
     }  

     for (let id = 0; id < dato.pages.length; id++){
      let data = dato.pages[id]
      let filterByRadio = data.filter(element => element.type == 'Radio') 
      console.log('filterByRadio', filterByRadio);  
         for (let i = 0; i < filterByRadio.length; i++) {
            if( filterByRadio[i].name == event.target.name){
              console.log('For-answer', filterByRadio[i].name);
              console.log('For-answer2', event.target.name) ;           
               if(filterByRadio[i].length > 0 &&filterByRadio[i].answers[0] != event.target.defaultValue){
                 console.log("entro al if de radio");
                filterByRadio[i].answers[0] = [event.target.defaultValue];
                
               }else{
                console.log("entro al else de radio");
                filterByRadio[i].answers[0] =event.target.defaultValue
               }

            }       
         }             
     } 

    }
   
    return dato;
   });
  }

  @HostListener('click', ['$event'])
  onClick(event: any): void {     
    // ////console.log('clickCheck', event); 
   let dataclick= this.structure.map(function(dato){ // pasar valor a comparar 
      //  ////console.log('array', dato.pages[0])
      //  ////console.log('Clickeado', event)
      var path = event.path || (event.composedPath && event.composedPath());  
       for (let id = 0; id < dato.pages.length; id++){
        let data = dato.pages[id]
        let filterByRadio = data.filter(element => element.type == 'Radio') 
        //console.log('filterByRadioonclick', filterByRadio)  
        for (let i = 0; i < filterByRadio.length; i++) {
          if( filterByRadio[i].name == path[0].name){
            // //console.log('For-answer-Onclick', filterByRadio[i].answers[0])
            console.log('LEnght',filterByRadio[i].length) 
            if(filterByRadio[i].answers== undefined){
              filterByRadio[i].answers=[];
            }else{
              if(filterByRadio[i].answers.length > 0){
                if(filterByRadio[i].answers[0] !== path[0].defaultValue){
                  filterByRadio[i].answers[0] =  path[0].defaultValue;
                  
                 }
              } 
            }   
       

          }       
       } 
  
       
       }     
        // this.respondentForm.structure
     return dato;
  });
  //console.log('dato',dataclick)
  
  }


  

  constructor(private fomrsService:FomrsService,
              private respondentFormsService: RespondentFormsService,
              private rutaActiva: ActivatedRoute,
              private router: Router,
              public dialog: MatDialog,
              public meetingsService: MeetingService,
              private modalService: NgbModal,              
              private authService: AuthService) {
                this.dataSesion = this.authService.getDataSesion();
                this.minDate = new Date(2020, 0, 1);
                this.maxDate = new Date();
                this.dataConfMeet = {
                  type: 'status_diagnosed',
                  id: '',
                  status: 'attended'
                };           
                
    this.form = document.getElementById('editRespondentForm');
    this.tabs = document.getElementsByClassName('tab');
    this.pages = [];
    this.currentTab = 0;
    this.respondentForm = {
      dfh_id: null,
      name: null,
      description: null,
      customer_id: null,
      structure: null,
      answered_check: false,
      respondents_id: null,
      custf_id: null,
      created_on: null,
      diagnostic_detail: null, 
      status: null,
      total_weight_patient:'',
      hash_patient:'',
      result_patient:''     
    };
    this.titleMessage = '';
    this.message = '';
  }

  addRmvAnswered(counter: number, lblName: string): void {
    let answered = false;
    if (counter !== 0) {
      answered = true;
    }

    this.AddRmvAnswered.emit({
      question: lblName,
      answered
    });
    ////console.log( 'this.AddRmvAnswered', this.AddRmvAnswered)
  }

  open(content,data) {
  this.urlPDF =globalData.urls.fileURl+'/pdf/forms/'+ data;
    // this.urlPDF = 'http://173.249.10.18:6630/pdf/forms/'  + data; 
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  openPDF(data): void {
    //console.log('pdf data',data);
    const dialogRef = this.dialog.open(ModalPdfViewComponent,{
      
     
      width: '600px',
      data:  data,
      // disableClose: 
      panelClass: 'custom-modalbox'
    });    
    // dialogRef.afterClosed().subscribe(res=>{    
    //   ////console.log(res);
    //   this.router.navigate(['medical-dashboard']);                  
    // })    
   }

  ngOnInit(): void {
    this.actualPatient = localStorage.getItem('patientSelected');
    const { id } = this.rutaActiva.snapshot.params;
    this.id = id;
    this.getByIdRespForm(id);
    this.validateSessionApp();
    // this.dataConfMeet.id = this.rutaActiva.snapshot.params;
    this.getMeet();
   
  }

  validateSessionApp(): any {
    if (localStorage.getItem('updatesession') === null) {
      localStorage.setItem('updatesession', 'true');
      location.reload();
    }
  }
  


  getMeet(){ 
    if(this.dataSesion.logical_medical!="urology"){
      this.meetingsService.getMeetings().subscribe(resp=>{
        ////console.log('respuestaGetMeet',this.rutaActiva.snapshot.params);  
        let id = this.rutaActiva.snapshot.params;
        let data= resp.resp
        let dataFilter = data.filter(element => element.dfh_id == id.id && element.status != 'rescheduled')
        ////console.log('respuestaGetMeet',dataFilter[0].mt_id);  
        this.dataConfMeet.id = dataFilter[0].mt_id 
      }),erro=>{}////console.log('error',erro);
    }

    
  }

  getByIdRespForm(id): any {
    this.respondentFormsService.getByIdRespForm(id).subscribe(
      resp => {
        if (resp) {
          this.respondentForm = resp.resp[0];
          console.log('this.respondentForm',this.respondentForm);
          this.structure = this.respondentForm.structure;
          this.comentarios = this.respondentForm.presumption;
          this.pre_status = resp.resp[0].status;
          //console.log('pre_status',this.pre_status)
          //console.log('datos d estrucutura',resp.resp);
          if(this.dataSesion.logical_medical=='urology') {
            this.calculatorByAnswer(this.structure);
          }         
          //Guardar cambios en este array  
        }
      },
      error => {
        ////console.log(error);
      }
    );
  }

  updateRespForm(): void {
    this.calculatorByAnswer(this.structure);
    this.respondentForm['diagnosis'] = this.diagnosis;
    this.respondentForm['presumption'] = this.comentarios;
    this.respondentForm['diagnostic_detail'] = JSON.stringify(this.structure);
    // this.respondentForm['type']= 'diagnosted'
    ////console.log('this.respondentForm',this.respondentForm)      
    this.respondentFormsService.updateRespForm(this.respondentForm,'diagnosed').subscribe(
      resp => { 
        ////console.log(':::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::')       
        ////console.log(resp);         
        if (resp.correct) {
          this.updateMeeting()
          ////console.log(resp.resp);
          this.titleMessage = 'Diagnóstico';
          this.message = 'Diagnóstico guardado';
          this.openAlertModal()
        }
      },
      error => {
        ////console.log(error);
      }
    );     
  }

    validateForm(): boolean {
      let valid = true;
      let i;  
      const tabs = document.getElementsByClassName('tab');
  
      return valid;
    }

    updateMeeting(){   
      this.meetingsService.confirmMeeting(this.dataConfMeet).subscribe(resp => {
        ////console.log('resp', resp)
      });
    }

    onSubmit(): any {

      let urologyValidation = true;
      
    let dataValue: any= document.getElementById("diagnosisRespond");
    //console.log('respuesta document', dataValue.value)
    this.diagnosis = dataValue.value;
       
        this.warnin=false;
        let answerNum: number = 0;
        this.form = document.getElementsByClassName('presenter');  
       
        this.structure.forEach( async (est: any, i) => {
          est.pages.forEach((pg, j) => { 
            pg.forEach( async (q, n) => {
              console.log('this.strucuture************',q);
              let arrAux: any = [];
              let lengthQ = 1;  
              switch (q.type) {
                case 'Radio':
                case 'Checkbox':
                    lengthQ = q.items.length;                   
                  break;
                default:                    
              } 
              // Validación unacamanete para urologia(se debe respetar el nombre de los parametros "Tipo Perfil Flujometría:" "Obstruido:")
              switch (q.name){
                case 'Tipo Perfil Flujometría:':
                  if(q.answers == undefined){
                    console.log('no tiene respuestas');
                    alert("Debe contestar el campo 'Tipo Perfil Flujometría'");
                    urologyValidation = false;
                  }
                  break;
                case 'Obstruido:':
                  if(q.answers == undefined){
                    console.log('no tiene respuestas');
                    alert("Debe contestar el campo 'Obstruido'");
                    urologyValidation = false;
                  }
                  break;  
              }
              

              answerNum = answerNum + lengthQ;
            });
          });
        });  
    
       
  
        this.respondentForm.custf_id = this.id;
        // this.respondentForm.structure = this.structure;
        if(this.dataSesion.logical_medical=='urology'){
          // this.respondentForm['presumption'] = ' - ';
        }else{
          const presumption: any = document.getElementById('presumption');
          this.respondentForm['presumption'] = presumption.value;
        }
      
        this.respondentForm['diagnosis'] = this.diagnosis;
        this.respondentForm['status'] = 'diagnosed';
        if(urologyValidation)
          this.updateRespForm();
        

    }

    openMdlMeeting(): void {
      this.upTop();
      this.mdlMessage = document.getElementById('mdlMessage');
      this.mdlMessage.style.display = 'block';
    }

    closeMdlMessage(): void {
      this.mdlMessage = document.getElementById('mdlMessage');
      this.mdlMessage.style.display = 'none';
      this.router.navigate(['medical-dashboard']);
    }

    upTop(): void {
      window.scrollTo(0, 0);
    }

    openAlertModal(): void {
      const dialogRef = this.dialog.open(ModalAlertComponent,{
        width:'350px',
        data:  this.message,
        disableClose: true
      });    
      dialogRef.afterClosed().subscribe(res=>{    
        ////console.log(res);
        this.router.navigate(['medical-dashboard']);                  
      })    
     }

     SeleccionDeDataPicker(even){
       ////console.log('this.date',even) //para recuperar el valor recuperado del dataPicker.
       ////console.log('this.id',even.targetElement.id) //para identificar el nombre de la pregunta.

       this.structure.map(function(dato){
        for (let id = 0; id < dato.pages.length; id++){
          let data = dato.pages[id] 
          let filterByDatapiker = data.filter(element => element.type == 'Datepicker'); 
          for (let i = 0; i < filterByDatapiker.length; i++) {
            if( filterByDatapiker[i].name == even.targetElement.id){
              //////console.log('For-answer', filterByRadio[i].answers[0])            
               if(filterByDatapiker[i].answers[0] !== even.targetElement.value){
                filterByDatapiker[i].answers[0] = even.targetElement.value
                 ////console.log('Se agrego', even.targetElement.value) 
               }
            }
          }
        }
        return dato
      })

     }

     calculatorByAnswer(dataa){

      console.log('================ Data ingreso: ',dataa);
       let array =[];
       let id8Value;
      dataa.map(function(dato){
        let filterByRolPatient = dataa.filter(element => element.role== "respondent")
        //console.log('DatosMapeados',filterByRolPatient);        
       for (let id = 0; id < filterByRolPatient[0].pages.length; id++){
         let data = filterByRolPatient[0].pages[id];         
         //console.log('for de pages',data);
         for(let i=0;i < data.length; i++){
         let id8answers = data[7].answers[0];         
           let indexAnswers = data[i];    
            let dataAnswerExtract =indexAnswers.answers;       
            let items = indexAnswers.items
            let weight =    indexAnswers.weight;
            //saco el valor de id8
            let finIndex7= data[7].items.findIndex(element=>element==id8answers);
            //console.log('finIndex7',finIndex7)
            id8Value = data[7].weight[finIndex7];
            for(let id = 0; id < dataAnswerExtract.length; id++) {
              let dataAnswer = dataAnswerExtract[id];
              let findByIndex = items.findIndex(element=>element == dataAnswer );
              //console.log('respuestaIndex', findByIndex);
              if(weight[findByIndex] != undefined && findByIndex != -1){         
                                   
                    array.push(parseInt(weight[findByIndex]));                    
                    //console.log('Datos en array', array);       
                                   
              }
            }      
          
         }
        //  let filterByData = data.filter(element => element.type == 'Number'|| element.type == 'Email' || element.type == 'Text'|| element.type == 'Textarea' );             

       }
      
       return dato;
      });   
      let total=0;
      for(let i of array) total+=i;  
      
      let val8=parseInt(id8Value);
      console.log('VALOR 8: ', val8);
      console.log('VALOR TOTAL: ', total);
      let totoal=total/2 - val8;   
      this.resultWeight = totoal + '-' + id8Value;
      //console.log('*********totalPesos',this.resultWeight);
      this.respondentForm['result_patient']=this.resultWeight;
     }
}
