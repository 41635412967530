import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../../services/auth.service';
import { UsersService } from '../../../services/users.service';
import { Router } from '@angular/router';
import { CustomersFormsService } from '../../../services/customers-forms.service'
import { MeetingService } from '../../../services/meeting.service';
import { RespondentFormsService } from '../../../services/respondent-forms.service';
import { FormControl, Validators } from '@angular/forms';



@Component({
  selector: 'app-modal-create-user',
  templateUrl: './modal-create-user.component.html',
  styleUrls: ['./modal-create-user.component.css']
})
export class ModalCreateUSerComponent implements OnInit {
  

  public idNumber: string;
  public clinicHistoryText:string;
  public authForm: any;
  public formToSendData: any;
  public customerForm: any;
  public IdUserCreate;
  public customerData : any;
  public dataSesion;
  public forms;
  public formsCardiology;
  public selected;
  public title_text ;
  public channel;
  public clinicsData:any;
  public msgDone = false;
  public mailSender:any;
  public patientMail:any;
  public userDataNcrypt;
  public mailUserSender;
  public disabledBTN = false;
  public idStablihsmntCardiology;
  public respondentIDByConsult;
  public aditionalForm;
  public btnCreate = true;
  public frame = 0;
  public createFormFrame = 0;
  public idUser;
  public generalBoolBTN = true;

  emailControl: FormControl = new FormControl('',[Validators.required,Validators.email]);

  constructor(public dialogRef: MatDialogRef <ModalCreateUSerComponent>,@Inject(MAT_DIALOG_DATA)public message:string,
              public authService: AuthService,
              public userService: UsersService,
              public router: Router,
              public meetingService:MeetingService,
              private customerService: CustomersFormsService,
              public RespondentFormsSrv: RespondentFormsService) {
    this.authForm = {
      usr_id: null,
      doc_id: null,
      google_id: null,
      phone: null,
      code: 0,
      name: null,
      password: null,
      email: null,
      role: 'respondent',
      provider: 'default',
      status: 'default',
      created_on: null,
      int_phone_codes: null,
      id_customer: null,
      id_stablishment:null,
      clinic_history:null     
    };

    this.formToSendData= {
      nameForm: null,//'hola',
      description:  null,//'hola',
      customer_id: null,//2,
      created_on : null,     
      structure: null,//'hola',
      answered_check : false,
      respondents_id: null,//229,
      custf_id: null,//3,
      status: 'onhold',
      specialist: null,
      id_stablishment: null                
    }

    this.mailSender= {
      headerMail:'Registro de nuevo paciente',
      nameDoctor:'Prueba',
      mailDoctor:'sergionexx1@gmail.com',
      text:"mensaje de prueba",
      nameUser:'Sergio',
      passUser:'1234'                
    }

    this.patientMail= {
      headerMail:'Plataforma IPSS - Datos creacion paciente',
      nameDoctor:'Prueba',
      mailDoctor:'',
      text:"mensaje de prueba",
      nameUser:'Sergio',
      passUser:'1234'                
    }
    this.userDataNcrypt = {
      usr_data:'',
      hash:localStorage.getItem('hash_stablishment')
    }
  
   }

  ngOnInit(): void {
    this.msgDone = false;
    console.log('CustomerID**************',localStorage.getItem('customer_id'))

    if(localStorage.getItem('customer_id') == "2"){
      this.channel = 2;
      this.title_text ='Número de Paciente ';  
    }else{
      this.title_text = 'Número de cédula ';    
      this.channel = 1;
    }
    
    console.log('Estrucutura recuperada', JSON.parse(localStorage.getItem('customerData')));
    this.customerData = JSON.parse(localStorage.getItem('customerData'));
    console.log('Date',Date.now())
    this.getDatabyIdCustomer()

    this.dataSesion = this.authService.getDataSesion();
    console.log('dataSession',this.dataSesion);
    if(this.dataSesion.logical_medical=='urology' || this.dataSesion.logical_medical=='preconsult'){
      if(this.dataSesion.id_stablishment == '0'){
        let data =  JSON.parse(localStorage.getItem('clinics'));
        this.clinicsData = data.clinics
        console.log('clinicas 0',this.clinicsData.clinics);
      }else{
        let clinics = JSON.parse(localStorage.getItem('clinics'));
        let dataToFilter = clinics.clinics;
        this.clinicsData = dataToFilter.filter(element=>element.code== this.dataSesion.id_stablishment);
        console.log('clinicas filter',this.clinicsData);
      }
       
    }
    localStorage.getItem('urlToMail');
   
    
  }

  closeModal(){
    this.dialogRef.close();
    location.reload(); 
   }    
   verifyUser(){
     if(this.selected != null && this.selected != ''){
      this.btnCreate  = false;
      this.userService.getPatientByName(this.idNumber.toString(),this.dataSesion.customerId,this.selected).subscribe(res=>{
        console.log('respuesta verifyUser',res);
        if(res.resp.length ==0){
          this.createUSer();
          this.btnCreate = false;
        }else{
          alert('El usuario que ha ingresado ya existe');
          this.btnCreate = true;
        }    
      });
     }else{
       alert('Debe seleccionar un centro médico');
     }

  }

  verifyUserUca(){
    this.userService.getPatientByName(this.idNumber.toString(),this.dataSesion.customerId,this.idStablihsmntCardiology.id_stablishment).subscribe(res=>{
      console.log('respuesta verifyUser',res);
    
      if(res.resp.length ==0){
        this.createUSer();
      }else{
        this.respondentIDByConsult = res.resp[0].usr_id
        this.addFormInUser();
      }    
    });
  }


  verifyUserCardiology(frame){
    this.generalBoolBTN = false
    this.frame = frame;
    
    switch(frame){
      case 1:
        let userDecryptId ='';

        let userDataDcrypt = {
          usr_data:this.idNumber.toString(),
          hash:localStorage.getItem('hash_stablishment')
        }        
        
        console.log('userDataDcrypt',userDataDcrypt);
        let userIdDataNcrypt = this.authService.ncryptUserData(userDataDcrypt).subscribe(res=>{
          console.log('data Ncrypt', res.data.name);
          userDecryptId = res.data.name;
          this.userService.getPatientByName(userDecryptId,this.dataSesion.customerId,this.dataSesion.id_stablishment).subscribe(res=>{
            console.log('respuesta verifyUser',res);    
           
            if(res.resp.length ==0){
              console.log('usuario NO existe');
              this.createFormFrame = 2;  
              this.generalBoolBTN = true;             
              // this.btnCreate = false;
            }else{
              this.idUser = res.resp[0].usr_id
              this.frame =2
              //validar que el paciente tenga creado el formulario "Datos personales"
              let respondent_id = res.resp[0].usr_id
               this.RespondentFormsSrv.getAllDataByIdUsrRespForm(respondent_id).subscribe(res=>{
               console.log('respuesta validación form create',res);
               //Se filtra el 00020 en caso de que se haya creado
               let dataRes = res.resp;
               let dataFilter = dataRes.filter(element => element.id_stablishment == '00020');
               console.log('dataFilter', dataFilter);         
      
               if (dataFilter.length != 0){
                 console.log('Ya tiene un formulario de Datos personales');
                 this.createFormFrame = 1;   
                 this.generalBoolBTN = true;    
               }
               else{
                console.log('No tiene datos personales creado, creando...');
                this.createFormFrame = 2;
                this.generalBoolBTN = true;
                //  this.aditionalForm;              
                // this.respondentIDByConsult = res.resp[0].usr_id
                // this.addFormInUser();
                // this.btnCreate = true;
               }
                                                      
                console.log('validando formuario Datos personales');
                console.log(this.forms);
             });            
              // this.respondentIDByConsult = res.resp[0].usr_id
              // this.addFormInUser();
              // this.btnCreate = true;
            }
          
          }); 
        });
        


        break;

      case 2:

        this.createUSer();
        this.createFormFrame = 3;
        this.generalBoolBTN = true;
        
        
        break;
      case 3: 
        let formSelect = this.forms.filter(element => element.name == this.idStablihsmntCardiology.name);
        console.log('respuestaCreateUSer',this.idUser );
        this.IdUserCreate = this.idUser;// se recupera id de usuario creado
        localStorage.setItem('patientSelected',this.idNumber.toString());
        this.formToSendData.respondents_id = this.idUser;
        this.formToSendData.structure =  formSelect[0].structure ;
        this.formToSendData.nameForm =  formSelect[0].name;
        this.formToSendData.description =  formSelect[0].description;
        this.formToSendData.customer_id =  formSelect[0].customer_id;
        this.formToSendData.custf_id =  formSelect[0].customer_id;
        this.formToSendData.created_on = Date.now();
        this.formToSendData.specialist = parseInt(localStorage.getItem('id'));
        if(formSelect[0].id_stablishment  == "00020"){
          this.formToSendData.id_stablishment = "00020";
        }else{
          this.formToSendData.id_stablishment = this.dataSesion.id_stablishment;        
        }   
        this.createForm()    
        this.createFormFrame = 3;
        this.generalBoolBTN = true;
        break;
      

    
      

    }

    // this.btnCreate  = false;
    this.userService.getPatientByName(this.idNumber.toString(),this.dataSesion.customerId,this.dataSesion.id_stablishment).subscribe(res=>{
      console.log('respuesta verifyUser',res);    
     
      if(res.resp.length ==0){
        // this.createUSer();
        // this.btnCreate = false;
      }else{
        //validar que el paciente tenga creado el formulario "Datos personales"
        let respondent_id = res.resp[0].usr_id
         this.RespondentFormsSrv.getAllDataByIdUsrRespForm(respondent_id).subscribe(res=>{
         console.log('respuesta validación form create',res);
         //Se filtra el 00020 en caso de que se haya creado
         let dataRes = res.resp;
         let dataFilter = dataRes.filter(element => element.id_stablishment == '00020');
         console.log('dataFilter', dataFilter);         

         if (dataFilter.length != 0){
           console.log('Ya tiene un formulario de Datos personales');
   
         }
         else{
          console.log('No tiene datos personales creado, creando...');
           this.aditionalForm;
          
          // this.respondentIDByConsult = res.resp[0].usr_id
          // this.addFormInUser();
          // this.btnCreate = true;
         }
         
         console.log('validando formuario Datos personales');
        console.log(this.forms);
       });
        
        // this.respondentIDByConsult = res.resp[0].usr_id
        // this.addFormInUser();
        // this.btnCreate = true;
      }
    
    });
  }   
     

   authUSer(){
    console.log('respuestaClicked');
    this.authService.auth(this.authForm).subscribe(res=>{
      console.log('respuestaAuth',res);
    });
   }
   createUSer(){
     if(this.dataSesion.logical_medical == 'urology' || this.dataSesion.logical_medical == 'preconsult'){
            //filtrar por forms seleccionado
          let formSelect = this.forms;          
          let filterfirstForm =  this.forms.filter(element => element.name == 'IPSS'); 
          
          console.log('FormulariosFiltrados!',formSelect);
          let passRamdom = Math.floor(1000 + Math.random() * 9000);
          this.authForm.doc_id = this.idNumber.toString();
          this.authForm.name = this.idNumber.toString();
          this.authForm.password = passRamdom.toString();
          this.authForm.id_customer = parseInt(localStorage.getItem('customer_id')) ;
          this.authForm.created_on = Date.now();
          this.authForm.id_stablishment = this.selected;
          this.authForm.clinic_history = this.clinicHistoryText;
          this.authForm.email = this.mailUserSender;
         
         console.log('salida',this.authForm);
         this.userService.createUser(this.authForm).subscribe(res => {
           if(this.dataSesion.logical_medical == 'urology'){
            console.log('respuestaCreateUSer',res)
            this.IdUserCreate = res.resp.usr_id;// se recupera id de usuario creado
            localStorage.setItem('patientSelected',this.idNumber.toString());;
            this.formToSendData.respondents_id = this.IdUserCreate;
            this.formToSendData.structure =  filterfirstForm[0].structure;
            this.formToSendData.nameForm =  filterfirstForm[0].name;
            this.formToSendData.description =  filterfirstForm[0].description;
            this.formToSendData.customer_id =  filterfirstForm[0].customer_id;
            this.formToSendData.custf_id =  filterfirstForm[0].customer_id;
            this.formToSendData.created_on = Date.now();
            this.formToSendData.specialist = parseInt(localStorage.getItem('id'));
            this.formToSendData.status = 'onhold/onhold';
            this.formToSendData.id_stablishment = this.dataSesion.id_stablishment;
 
             // this.mailSender.nameDoctor = 
             this.mailSender.nameDoctor = localStorage.getItem('name');
             this.mailSender.mailDoctor = localStorage.getItem('email');
             this.mailSender.nameUser = this.idNumber.toString();
             this.mailSender.passUser =passRamdom.toString();
             
             this.mailSender.link =  localStorage.getItem('urlToMail');
             this.mailSender.nombrePaciente = this.idNumber.toString();
             this.mailSender.text = 'Estimad@ '+localStorage.getItem('name') +', se ha ingresado correctamente el paciente: '+ this.idNumber.toString() +', para iniciar sesion vaya al siguiente link '+ localStorage.getItem('urlToMail') + ' y acceda con las credenciales del usuario ingresado. ';
             //this.mailSender.user
             this.patientMail.nameDoctor = 'usuario ';
             this.patientMail.mailDoctor = this.mailUserSender;
             this.patientMail.nameUser = this.idNumber.toString();
             this.patientMail.passUser =passRamdom.toString();
             
             this.patientMail.link =  localStorage.getItem('urlToMail');
             this.patientMail.nombrePaciente = this.idNumber.toString();
             this.patientMail.text = 'Estimad@ '+this.patientMail.nameDoctor +' '+ this.idNumber.toString() + ', para iniciar sesion vaya al siguiente link ' + localStorage.getItem('urlToMail') + ' y acceda con sus credenciales. ';
             this.disabledBTN=true;
            //CrearFormulario
            this.createForm()
           }else if(this.dataSesion.logical_medical == 'preconsult'){
            /** Voy a filtrar los formularios del customer por el id_stablishment del formulario seleccionado*/
              this.IdUserCreate = res.resp.usr_id;// se recupera id de usuario creado
              let filterFormByIdStablisment = formSelect.filter(element=>element.id_stablishment == this.selected);
              let specialistId = parseInt(localStorage.getItem('id'));
              let createdDate = Date.now();

              let filteredMap = filterFormByIdStablisment.map((form: any) => {
                return {
                  respondents_id: parseInt(this.IdUserCreate),
                  structure: JSON.stringify(form.structure),
                  initial_form: form.structure[0].initial_form,
                  activation_status: 'I',
                  name:  form.name,
                  description: form.description,
                  customer_id: parseInt(form.customer_id),
                  custf_id:  parseInt(form.custf_id),
                  created_on: createdDate,
                  specialist: specialistId,
                  status: 'onhold/onhold',
                  id_stablishment: this.dataSesion.id_stablishment,
                  periodic: JSON.parse(form.periodic)
                }
              });

              console.log('respuestaCreateUser',filteredMap);



              /**
               * 
               * 
               * Referencia de creacion de formularios
               * 
               */
              // this.IdUserCreate = res.resp.usr_id;// se recupera id de usuario creado
              // localStorage.setItem('patientSelected',this.idNumber.toString());;
              // this.formToSendData.respondents_id = this.IdUserCreate;
              // this.formToSendData.structure = filterFormByIdStablisment[0].structure;
              // this.formToSendData.nameForm =  filterFormByIdStablisment[0].name;
              // this.formToSendData.description =  filterFormByIdStablisment[0].description;
              // this.formToSendData.customer_id =  filterFormByIdStablisment[0].customer_id;
              // this.formToSendData.custf_id =  filterFormByIdStablisment[0].custf_id;
              // this.formToSendData.created_on = Date.now();
              // this.formToSendData.specialist = parseInt(localStorage.getItem('id'));
              // this.formToSendData.status = 'onhold/onhold';
              // this.formToSendData.id_stablishment = this.dataSesion.id_stablishment;
             
               // this.mailSender.nameDoctor = 
               this.mailSender.nameDoctor = localStorage.getItem('name');
               this.mailSender.mailDoctor = localStorage.getItem('email');
               this.mailSender.nameUser = this.idNumber.toString();
               this.mailSender.passUser =passRamdom.toString();
               
              this.mailSender.link =  localStorage.getItem('urlToMail');
              this.mailSender.nombrePaciente = this.idNumber.toString();
               this.mailSender.text = 'Estimad@ '+localStorage.getItem('name') +', se ha ingresado correctamente el paciente: '+ this.idNumber.toString() +', para iniciar sesion vaya al siguiente link '+ localStorage.getItem('urlToMail') + ' y acceda con las credenciales del usuario ingresado. ';
               //this.mailSender.user
               this.patientMail.headerMail = 'Preconsult - Datos creacion paciente';
               this.patientMail.nameDoctor = 'usuario ';
               this.patientMail.mailDoctor = this.mailUserSender;
               this.patientMail.nameUser = this.idNumber.toString();
               this.patientMail.passUser =passRamdom.toString();
               
              this.patientMail.link =  localStorage.getItem('urlToMail');
              this.patientMail.nombrePaciente = this.idNumber.toString();
               this.patientMail.text = 'Estimad@ '+this.patientMail.nameDoctor +' '+ this.idNumber.toString() + ', para iniciar sesion vaya al siguiente link ' + localStorage.getItem('urlToMail') + ' y acceda con sus credenciales. ';
               this.disabledBTN=true;
               this.createForms(filteredMap);
               //this.createForm();
               this.sendMailCredentialsToUser();
               this.sendMailToSpecialist();
               alert('Paciente creado con éxito');
               this.dialogRef.close();
             
           }

    }, error => console.log('ERROR: ',error))//console.log(err)

     }else{
      //filtrar por forms seleccionado
      if(this.dataSesion.logical_medical == 'cardiology'){
        let usrData;
        console.log('antes del filter', this.forms);
        console.log('idStablihsmntCardiology',this.idStablihsmntCardiology);
       let formSelect = this.forms.filter(element => element.name == this.idStablihsmntCardiology.name);
       this.userDataNcrypt.usr_data=this.idNumber.toString();
       this.userDataNcrypt.hash = localStorage.getItem('hash_stablishment');
       this.authService.ncryptUserData(this.userDataNcrypt).subscribe(res=>{
        console.log('this.authService.ncryptUserData',res.data.name);
        //Se recupera los datos encryptados y se crea el usuario
        usrData = res.data.name;
        
      console.log('FormulariosFiltrados!',formSelect);
  
      this.authForm.doc_id = usrData;
      this.authForm.name = usrData;
      this.authForm.password = this.idNumber.toString();
      this.authForm.id_customer = parseInt(localStorage.getItem('customer_id')) ;
      this.authForm.created_on = Date.now();
      this.authForm.id_stablishment = this.dataSesion.id_stablishment;
      this.authForm.clinic_history = '';
      console.log('salida this.authForm',this.authForm)
      this.userService.createUser(this.authForm).subscribe(res => {
        console.log('respuestaCreateUSer',res);
        
        this.IdUserCreate = res.resp.usr_id;// se recupera id de usuario creado
        localStorage.setItem('patientSelected',this.idNumber.toString());
        this.formToSendData.respondents_id = this.IdUserCreate;
        this.formToSendData.structure =  formSelect[0].structure ;
        this.formToSendData.nameForm =  formSelect[0].name;
        this.formToSendData.description =  formSelect[0].description;
        this.formToSendData.customer_id =  formSelect[0].customer_id;
        this.formToSendData.custf_id =  formSelect[0].customer_id;
        this.formToSendData.created_on = Date.now();
        this.formToSendData.specialist = parseInt(localStorage.getItem('id'));
        if(formSelect[0].id_stablishment  == "00020"){
          this.formToSendData.id_stablishment = "00020";
        }else{
          this.formToSendData.id_stablishment = this.dataSesion.id_stablishment;        
        }
        
        //datos para envio de mail
       
  
        
        //CrearFormulario
        this.createForm()
      })
      });
  //console.log(err)
      }else{
        let formSelect = this.forms.filter(element => element.name == this.idStablihsmntCardiology.name);

        console.log('FormulariosFiltrados!',formSelect);
    
        this.authForm.doc_id = this.idNumber.toString();
        this.authForm.name = this.idNumber.toString();
        this.authForm.password = this.idNumber.toString();
        this.authForm.id_customer = parseInt(localStorage.getItem('customer_id')) ;
        this.authForm.created_on = Date.now();
        this.authForm.id_stablishment = this.idStablihsmntCardiology.id_stablishment;
        this.authForm.clinic_history = '';
        console.log('salida',this.authForm)
        this.userService.createUser(this.authForm).subscribe(res => {
          console.log('respuestaCreateUSer',res);
          this.IdUserCreate = res.resp.usr_id;// se recupera id de usuario creado
          localStorage.setItem('patientSelected',this.idNumber.toString());
          this.formToSendData.respondents_id = this.IdUserCreate;
          this.formToSendData.structure =  formSelect[0].structure ;
          this.formToSendData.nameForm =  formSelect[0].name;
          this.formToSendData.description =  formSelect[0].description;
          this.formToSendData.customer_id =  formSelect[0].customer_id;
          this.formToSendData.custf_id =  formSelect[0].customer_id;
          this.formToSendData.created_on = Date.now();
          this.formToSendData.specialist = parseInt(localStorage.getItem('id'));
          //datos para envio de mail
         
    
          
          //CrearFormulario
          this.createForm()
        })//console.log(err)
         
    
    
        
      }

     }

   }


   AditionalForm(){
    this.authService.createForm(this.formToSendData).subscribe(resp => {
      console.log('Se a guardado el formulario con exito', parseInt(resp.resp)  )
    }
    )
   }

   addFormInUser(){
    let formSelect = this.forms.filter(element => element.name == this.idStablihsmntCardiology.name);
   
    this.formToSendData.respondents_id = this.respondentIDByConsult;
    this.formToSendData.structure =  formSelect[0].structure ;
    this.formToSendData.nameForm =  formSelect[0].name;
    this.formToSendData.description =  formSelect[0].description;
    this.formToSendData.customer_id =  formSelect[0].customer_id;
    this.formToSendData.custf_id =  formSelect[0].customer_id;
    this.formToSendData.created_on = Date.now();
    this.formToSendData.specialist = parseInt(localStorage.getItem('id'));    
    this.authService.createForm(this.formToSendData).subscribe(resp => {
      console.log('Se a guardado el formulario con exito', parseInt(resp.resp))
      //llevar a edit-respondent-form/252      
        this.router.navigate(['edit-respondent-form/'+ parseInt(resp.resp)])
        this.dialogRef.close();    
    }); 
   }

   createForms(forms: any[]){
    this.authService.createForm(forms).subscribe(resp => {
      console.log(resp);
      this.router.navigate(['edit-respondent-form/'+ parseInt(resp.resp)]);
      this.dialogRef.close();
    },err => console.log('error al crear el formulario',err)); 
   }

   createForm(){
    console.log('salida formToSendData',this.formToSendData)
    this.authService.createForm(this.formToSendData).subscribe(resp => {
      console.log('Se a guardado el formulario con exito', parseInt(resp.resp)  )
      //llevar a edit-respondent-form/252
      console.log('1');
      if(this.dataSesion.logical_medical == 'urology'){
        this.msgDone = true;
        this.sendMailCredentialsToUser();
        this.sendMailToSpecialist();
        localStorage.setItem('patientSelected',this.idNumber.toString());
        this.formToSendData.respondents_id = this.IdUserCreate;
        this.formToSendData.structure =  this.aditionalForm[0].structure ;
        this.formToSendData.nameForm =  this.aditionalForm[0].name;
        this.formToSendData.description =  this.aditionalForm[0].description;
        this.formToSendData.customer_id =  this.aditionalForm[0].customer_id;
        this.formToSendData.custf_id =  this.aditionalForm[0].customer_id;
        this.formToSendData.created_on = Date.now();
        this.formToSendData.specialist = parseInt(localStorage.getItem('id'));
        this.formToSendData.status = 'onhold/onhold';
        this.formToSendData.answered_check = true;
        this.AditionalForm();       
      }else{
        this.router.navigate(['edit-respondent-form/'+ parseInt(resp.resp)])
        this.dialogRef.close()
      }
    },err => console.log('error al crear el formulario',err));   
  }

   sendMailToSpecialist(){
    this.meetingService.sendMailToDoctor(this.mailSender).subscribe(res=>{
      console.log('sendMailTodoctor',res);
    })
   }
   sendMailCredentialsToUser(){
    this.meetingService.sendMailCredentialsToUser(this.patientMail).subscribe(res=>{
      console.log('sendMailUser',res);
    })
  }

  getDatabyIdCustomer(){
    this.customerService.getByIdCustForm(localStorage.getItem('customer_id')).subscribe(res => { // poner id de canal this.dataSesion.customerId
      console.log('Parse periodic',JSON.parse(res.resp[0].periodic))
      console.log('Customer_forms', res.resp);
      
      res.resp.sort(function(a, b) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        // names must be equal
        return 0;
      });

      if(this.dataSesion.logical_medical=='urology'){
        this.forms = res.resp;
        let filterByUnique = this.forms.filter(element=>element.id_stablishment == '00010');
        console.log('filterByUnique',filterByUnique);
        this.aditionalForm = filterByUnique;
      }else{
        if(this.dataSesion.logical_medical== 'cardiology'){   
          console.log('this.dataSesion.logical_medical', res);      
          let dataFilter = res.resp;
          this.forms = dataFilter.filter(element => element.id_stablishment == '00020' ||  element.id_stablishment == this.dataSesion.id_stablishment);   
          this.formsCardiology = dataFilter.filter(element =>  element.id_stablishment == this.dataSesion.id_stablishment);
          this.aditionalForm = dataFilter.filter(element =>  element.id_stablishment == '00020');
          console.log('getDatabyIdCustomer', this.forms)     

        }else{
          
          let filterByStablishment =  res.resp.filter(element=>element.customer_id == this.dataSesion.customerId);    
          console.log('filterByStablishment',filterByStablishment); 
          if(filterByStablishment.length == 0){          
            let filterByCero =  res.resp.filter(element=> element.id_stablishment == '0' );
            console.log('filterByCero', filterByCero);
            this.forms = filterByCero;
          }else{
            this.forms = filterByStablishment;          
          }       
       }
      }
       

    });  
    
  }

  onChange($event){
    console.log('$vent',$event)
  }

  Generar(){

  }

  chooseStablishment(data){
    
    console.log('chooseStablishment',data);
  }


}
