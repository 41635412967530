import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CustomersFormsService } from 'src/app/services/customers-forms.service';
import { MeetingService } from 'src/app/services/meeting.service';
import { RespondentFormsService } from 'src/app/services/respondent-forms.service';
import { UsersService } from '../../../services/users.service';
import { SpinnerService } from '../../../services/spinner.service';

@Component({
  selector: 'app-create-patient',
  templateUrl: './create-patient.component.html',
  styleUrls: ['./create-patient.component.css']
})
export class CreatePatientComponent implements OnInit, OnDestroy {


  public idNumber: string;
  public clinicHistoryText:string;
  public authForm: any;
  public formToSendData: any;
  public customerForm: any;
  public IdUserCreate;
  public customerData : any;
  public dataSesion;
  public forms;
  public formsCardiology;
  public selected;
  public title_text ;
  public channel;
  public clinicsData:any;
  public msgDone = false;
  public mailSender:any;
  public patientMail:any;
  public userDataNcrypt;
  public mailUserSender;
  public disabledBTN = false;
  public idStablihsmntCardiology;
  public respondentIDByConsult;
  public aditionalForm;
  public btnCreate = true;
  public frame = 0;
  public createFormFrame = 0;
  public idUser;
  public generalBoolBTN = true;
  
  patName : String = '';
  patLastName : String = '';
  
  periodic: string = 'normal'
  filterFormByIdStablisment: any[] = [];

  constructor(
    public authService: AuthService,
    public router: Router,
    public meetingService:MeetingService,
    private customerService: CustomersFormsService,
    public RespondentFormsSrv: RespondentFormsService,
    private userService: UsersService,
    private spinnerService: SpinnerService
  ) {
    this.userService.setOnCreatePatient(true);
  
    this.authForm = {
      usr_id: null,
      doc_id: null,
      google_id: null,
      phone: '',
      code: 0,
      name: null,
      password: null,
      email: null,
      role: 'respondent',
      provider: 'default',
      status: 'default',
      created_on: null,
      intPhoneCodes: "593",
      id_customer: null,
      id_stablishment:null,
      clinic_history:null    
    };

    this.formToSendData= {
      nameForm: null,//'hola',
      description:  null,//'hola',
      customer_id: null,//2,
      created_on : null,     
      structure: null,//'hola',
      answered_check : false,
      respondents_id: null,//229,
      custf_id: null,//3,
      status: 'onhold',
      specialist: null,
      id_stablishment: null                
    }

    this.mailSender= {
      headerMail:'Registro de nuevo paciente',
      nameDoctor:'Prueba',
      mailDoctor:'sergionexx1@gmail.com',
      text:"mensaje de prueba",
      nameUser:'Sergio',
      passUser:'1234'                
    }

    this.patientMail= {
      headerMail:'Plataforma IPSS - Datos creacion paciente',
      nameDoctor:'Prueba',
      mailDoctor:'',
      text:"mensaje de prueba",
      nameUser:'Sergio',
      passUser:'1234'                
    }
    this.userDataNcrypt = {
      usr_data:'',
      hash:localStorage.getItem('hash_stablishment')
    }
  
   }

  ngOnInit(): void {
    this.msgDone = false;
    console.log('CustomerID**************',localStorage.getItem('customer_id'))

    if(localStorage.getItem('customer_id') == "2"){
      this.channel = 2;
      this.title_text ='Número de Paciente ';  
    }else{
      this.title_text = 'Número de cédula ';    
      this.channel = 1;
    }
    
    console.log('Estrucutura recuperada', JSON.parse(localStorage.getItem('customerData')));
    this.customerData = JSON.parse(localStorage.getItem('customerData'));
    console.log('Date',Date.now())
    this.getDatabyIdCustomer();

    this.dataSesion = this.authService.getDataSesion();
    console.log('dataSession',this.dataSesion);
    if(this.dataSesion.logical_medical=='urology' || this.dataSesion.logical_medical=='preconsult'){
      if(this.dataSesion.id_stablishment == '0'){
        let data =  JSON.parse(localStorage.getItem('clinics'));
        this.clinicsData = data.clinics
        console.log('clinicas 0',this.clinicsData.clinics);
      }else{
        let clinics = JSON.parse(localStorage.getItem('clinics'));
        let dataToFilter = clinics.clinics;
        this.clinicsData = dataToFilter.filter(element=>element.code== this.dataSesion.id_stablishment);
        console.log('clinicas filter',this.clinicsData);
      }
       
    }
    localStorage.getItem('urlToMail');   
    
  }

  periodsAreValid(): boolean {
    let isValid: boolean = true;

    for(let key in this.customPeriodArray){
      for(let i = 0; i < this.customPeriodArray[key].period.length; i++){
        let period = this.customPeriodArray[key].period[i];
        if(period.amount <= 0){
          isValid = false;
          break;
        }
        if(period.unit == 'days'){
          period.amount *= 24;
          period.unit = 'hours';
        }else if(period.unit == 'months'){
          period.amount *= (24 * 30);
          period.unit = 'hours';
        }
      }
    }

    if(isValid){
      for(let key in this.customPeriodArray){
        this.customPeriodArray[key].period = this.customPeriodArray[key].period.map(value => value.amount.toString());
      }  
    }

    console.log(this.customPeriodArray);

    return isValid;
  }

   verifyUser(){
    if(!this.patName.trim() || !this.patLastName.trim()){
      alert('El campo del nombre es obligatorio');
    }else if(!this.periodsAreValid()){
      alert('Debe completar la información de la frecuencia correctamente!');
    }
    else if(this.selected != null && this.selected != ''){
      this.btnCreate  = false;
      console.log('VERIFICAR: ======> ',this.clinicHistoryText);
      this.userService.getPatientByClinicHistory(this.clinicHistoryText.toString(),this.dataSesion.customerId,this.selected).subscribe(res=>{
        console.log('respuesta verifyUser',res);
        if(res.resp.length ==0){
          this.createUSer();
          this.btnCreate = false;
        }else{
          alert('El usuario que ha ingresado ya existe');
          this.btnCreate = true;
        }    
      });
    }else{
      alert('Debe seleccionar un centro médico');
    }

  }

  verifyUserUca(){
    this.userService.getPatientByName(this.idNumber.toString(),this.dataSesion.customerId,this.idStablihsmntCardiology.id_stablishment).subscribe(res=>{
      console.log('respuesta verifyUser',res);
    
      if(res.resp.length ==0){
        this.createUSer();
      }else{
        this.respondentIDByConsult = res.resp[0].usr_id
        this.addFormInUser();
      }    
    });
  }


  verifyUserCardiology(frame){
    this.generalBoolBTN = false
    this.frame = frame;
    
    switch(frame){
      case 1:
        let userDecryptId ='';

        let userDataDcrypt = {
          usr_data:this.idNumber.toString(),
          hash:localStorage.getItem('hash_stablishment')
        }        
        
        console.log('userDataDcrypt',userDataDcrypt);
        let userIdDataNcrypt = this.authService.ncryptUserData(userDataDcrypt).subscribe(res=>{
          console.log('data Ncrypt', res.data.name);
          userDecryptId = res.data.name;
          this.userService.getPatientByName(userDecryptId,this.dataSesion.customerId,this.dataSesion.id_stablishment).subscribe(res=>{
            console.log('respuesta verifyUser',res);    
           
            if(res.resp.length ==0){
              console.log('usuario NO existe');
              this.createFormFrame = 2;  
              this.generalBoolBTN = true;             
              // this.btnCreate = false;
            }else{
              this.idUser = res.resp[0].usr_id
              this.frame =2
              //validar que el paciente tenga creado el formulario "Datos personales"
              let respondent_id = res.resp[0].usr_id
               this.RespondentFormsSrv.getAllDataByIdUsrRespForm(respondent_id).subscribe(res=>{
               console.log('respuesta validación form create',res);
               //Se filtra el 00020 en caso de que se haya creado
               let dataRes = res.resp;
               let dataFilter = dataRes.filter(element => element.id_stablishment == '00020');
               console.log('dataFilter', dataFilter);         
      
               if (dataFilter.length != 0){
                 console.log('Ya tiene un formulario de Datos personales');
                 this.createFormFrame = 1;   
                 this.generalBoolBTN = true;    
               }
               else{
                console.log('No tiene datos personales creado, creando...');
                this.createFormFrame = 2;
                this.generalBoolBTN = true;
                //  this.aditionalForm;              
                // this.respondentIDByConsult = res.resp[0].usr_id
                // this.addFormInUser();
                // this.btnCreate = true;
               }
                                                      
                console.log('validando formuario Datos personales');
                console.log(this.forms);
             });            
              // this.respondentIDByConsult = res.resp[0].usr_id
              // this.addFormInUser();
              // this.btnCreate = true;
            }
          
          }); 
        });
        


        break;

      case 2:

        this.createUSer();
        this.createFormFrame = 3;
        this.generalBoolBTN = true;
        
        
        break;
      case 3: 
        let formSelect = this.forms.filter(element => element.name == this.idStablihsmntCardiology.name);
        console.log('respuestaCreateUSer',this.idUser );
        this.IdUserCreate = this.idUser;// se recupera id de usuario creado
        localStorage.setItem('patientSelected',this.idNumber.toString());
        this.formToSendData.respondents_id = this.idUser;
        this.formToSendData.structure =  formSelect[0].structure ;
        this.formToSendData.nameForm =  formSelect[0].name;
        this.formToSendData.description =  formSelect[0].description;
        this.formToSendData.customer_id =  formSelect[0].customer_id;
        this.formToSendData.custf_id =  formSelect[0].customer_id;
        this.formToSendData.created_on = Date.now();
        this.formToSendData.specialist = parseInt(localStorage.getItem('id'));
        if(formSelect[0].id_stablishment  == "00020"){
          this.formToSendData.id_stablishment = "00020";
        }else{
          this.formToSendData.id_stablishment = this.dataSesion.id_stablishment;        
        }   
        this.createForm()    
        this.createFormFrame = 3;
        this.generalBoolBTN = true;
        break;
      

    
      

    }

    // this.btnCreate  = false;
    this.userService.getPatientByName(this.idNumber.toString(),this.dataSesion.customerId,this.dataSesion.id_stablishment).subscribe(res=>{
      console.log('respuesta verifyUser',res);    
     
      if(res.resp.length ==0){
        // this.createUSer();
        // this.btnCreate = false;
      }else{
        //validar que el paciente tenga creado el formulario "Datos personales"
        let respondent_id = res.resp[0].usr_id
         this.RespondentFormsSrv.getAllDataByIdUsrRespForm(respondent_id).subscribe(res=>{
         console.log('respuesta validación form create',res);
         //Se filtra el 00020 en caso de que se haya creado
         let dataRes = res.resp;
         let dataFilter = dataRes.filter(element => element.id_stablishment == '00020');
         console.log('dataFilter', dataFilter);         

         if (dataFilter.length != 0){
           console.log('Ya tiene un formulario de Datos personales');
   
         }
         else{
          console.log('No tiene datos personales creado, creando...');
           this.aditionalForm;
          
          // this.respondentIDByConsult = res.resp[0].usr_id
          // this.addFormInUser();
          // this.btnCreate = true;
         }
         
         console.log('validando formuario Datos personales');
        console.log(this.forms);
       });
        
        // this.respondentIDByConsult = res.resp[0].usr_id
        // this.addFormInUser();
        // this.btnCreate = true;
      }
    
    });
  }   
     

   authUSer(){
    console.log('respuestaClicked');
    this.authService.auth(this.authForm).subscribe(res=>{
      console.log('respuestaAuth',res);
    });
   }
   createUSer(){
     if(this.dataSesion.logical_medical == 'urology' || this.dataSesion.logical_medical == 'preconsult'){
            //filtrar por forms seleccionado
          let formSelect = this.forms;          
          let filterfirstForm =  this.forms.filter(element => element.name == 'IPSS'); 
          
          console.log('FormulariosFiltrados!',formSelect);
          let passRamdom = Math.floor(1000 + Math.random() * 9000);
          this.authForm.doc_id = this.idNumber?.toString() || '';
          this.authForm.password = passRamdom.toString();
          this.authForm.id_customer = parseInt(localStorage.getItem('customer_id')) ;
          this.authForm.created_on = Date.now();
          this.authForm.id_stablishment = this.selected;
          this.authForm.clinic_history = this.clinicHistoryText;
          this.authForm.email = this.mailUserSender;
          //let phoneDistinct = this.authForm.phone.split('').reverse().slice(0,4).reverse().join('');
          let identifier = Math.random().toString().substring(2,6);
          this.authForm.full_name = `${this.patName.trim()} ${this.patLastName.trim()}`;
          
          this.authForm.name = this.idNumber?.toString() || `${this.patLastName.trim().split(' ')[0].toUpperCase()}${this.patName[0].toUpperCase()}${identifier}`;
          
          this.authForm.periodicity_forms = this.filterFormByIdStablisment.map(form => {
            return {
              custf_id: form.custf_id,
              periodicity: form.periodic[this.periodicLevel[this.periodic]]
            }
          });
          
          if(this.periodic == 'customized'){
            for(let i = 0; i < this.authForm.periodicity_forms.length; i++){
              let id = this.authForm.periodicity_forms[i].custf_id;
              console.log(id);
              console.log(this.customPeriodArray);
              if(this.customPeriodArray.hasOwnProperty(`id-${id}`)){
                this.authForm.periodicity_forms[i].periodicity = this.customPeriodArray[`id-${id}`];
              }
            }
          }
         
          this.authForm.periodicity_forms = JSON.stringify(this.authForm.periodicity_forms);

         console.log('salida',this.authForm)
         this.userService.createUser(this.authForm).subscribe(res => {
           if(this.dataSesion.logical_medical == 'urology'){
            console.log('respuestaCreateUSer',res)
            this.IdUserCreate = res.resp.usr_id;// se recupera id de usuario creado
            localStorage.setItem('patientSelected',this.idNumber.toString());;
            this.formToSendData.respondents_id = this.IdUserCreate;
            this.formToSendData.structure =  filterfirstForm[0].structure;
            this.formToSendData.nameForm =  filterfirstForm[0].name;
            this.formToSendData.description =  filterfirstForm[0].description;
            this.formToSendData.customer_id =  filterfirstForm[0].customer_id;
            this.formToSendData.custf_id =  filterfirstForm[0].customer_id;
            this.formToSendData.created_on = Date.now();
            this.formToSendData.specialist = parseInt(localStorage.getItem('id'));
            this.formToSendData.status = 'onhold/onhold';
            this.formToSendData.id_stablishment = this.dataSesion.id_stablishment;
 
             // this.mailSender.nameDoctor = 
             this.mailSender.nameDoctor = localStorage.getItem('name');
             this.mailSender.mailDoctor = localStorage.getItem('email');
             this.mailSender.nameUser = this.authForm.name;
             this.mailSender.passUser =passRamdom.toString();
             this.mailSender.link =  localStorage.getItem('urlToMail');
             this.mailSender.nombrePaciente = this.authForm.name;
             this.mailSender.text = 'Estimad@ '+localStorage.getItem('name') +', se ha ingresado correctamente el paciente: '+ this.authForm.name +', para iniciar sesion vaya al siguiente link '+ localStorage.getItem('urlToMail') + ' y acceda con las credenciales del usuario ingresado. ';
             //this.mailSender.user
             this.patientMail.nameDoctor = 'usuario ';
             this.patientMail.mailDoctor = this.mailUserSender;
             this.patientMail.nameUser = this.authForm.name;
             this.patientMail.passUser =passRamdom.toString();
             this.patientMail.link =  localStorage.getItem('urlToMail');
             this.patientMail.nombrePaciente = this.authForm.name;
             this.patientMail.text = 'Estimad@ '+this.patientMail.nameDoctor +' '+ this.authForm.name + ', para iniciar sesion vaya al siguiente link ' + localStorage.getItem('urlToMail') + ' y acceda con sus credenciales. ';
             this.disabledBTN=true;
            //CrearFormulario
            this.createForm()
           }else if(this.dataSesion.logical_medical == 'preconsult'){
            /** Voy a filtrar los formularios del customer por el id_stablishment del formulario seleccionado*/
              this.IdUserCreate = res.resp.usr_id;// se recupera id de usuario creado
              
              let specialistId = parseInt(localStorage.getItem('id'));
              let createdDate = Date.now();

              let filteredMap = this.filterFormByIdStablisment.map((form: any) => {
                return {
                  respondents_id: parseInt(this.IdUserCreate),
                  structure: JSON.stringify(form.structure),
                  initial_form: form.structure[0].initial_form,
                  activation_status: 'I',
                  name:  form.name,
                  description: form.description,
                  customer_id: parseInt(form.customer_id),
                  custf_id:  parseInt(form.custf_id),
                  created_on: createdDate,
                  specialist: specialistId,
                  status: 'onhold/onhold',
                  id_stablishment: this.dataSesion.id_stablishment,
                  periodic: this.getPeriodic(form.custf_id)
                }
              });

              console.log('respuestaCreateUser',filteredMap);



              /**
               * 
               * 
               * Referencia de creacion de formularios
               * 
               */
              // this.IdUserCreate = res.resp.usr_id;// se recupera id de usuario creado
              // localStorage.setItem('patientSelected',this.idNumber.toString());;
              // this.formToSendData.respondents_id = this.IdUserCreate;
              // this.formToSendData.structure = filterFormByIdStablisment[0].structure;
              // this.formToSendData.nameForm =  filterFormByIdStablisment[0].name;
              // this.formToSendData.description =  filterFormByIdStablisment[0].description;
              // this.formToSendData.customer_id =  filterFormByIdStablisment[0].customer_id;
              // this.formToSendData.custf_id =  filterFormByIdStablisment[0].custf_id;
              // this.formToSendData.created_on = Date.now();
              // this.formToSendData.specialist = parseInt(localStorage.getItem('id'));
              // this.formToSendData.status = 'onhold/onhold';
              // this.formToSendData.id_stablishment = this.dataSesion.id_stablishment;
             
               // this.mailSender.nameDoctor = 
               this.mailSender.nameDoctor = localStorage.getItem('name');
               this.mailSender.mailDoctor = localStorage.getItem('email');
               this.mailSender.nameUser = this.authForm.name;
               this.mailSender.passUser =passRamdom.toString();
               this.mailSender.link =  localStorage.getItem('urlToMail');
               this.mailSender.nombrePaciente = this.authForm.name;
               this.mailSender.text = 'Estimad@ '+localStorage.getItem('name') +', se ha ingresado correctamente el paciente: '+ this.authForm.name +', para iniciar sesion vaya al siguiente link '+ localStorage.getItem('urlToMail') + ' y acceda con las credenciales del usuario ingresado. ';
               //this.mailSender.user
               this.patientMail.headerMail = 'Preconsult - Datos creacion paciente';
               this.patientMail.nameDoctor = 'usuario ';
               this.patientMail.mailDoctor = this.mailUserSender;
               this.patientMail.nameUser = this.authForm.name;
               this.patientMail.passUser =passRamdom.toString();
               this.patientMail.link =  localStorage.getItem('urlToMail');
               this.patientMail.nombrePaciente = this.authForm.name;
               this.patientMail.text = 'Estimad@ '+this.patientMail.nameDoctor +' '+ this.authForm.name + ', para iniciar sesion vaya al siguiente link ' + localStorage.getItem('urlToMail') + ' y acceda con sus credenciales. ';
               this.disabledBTN=true;
               this.createForms(filteredMap);
               //this.createForm();
               this.sendMailCredentialsToUser();
               this.sendMailToSpecialist();
               alert('Paciente creado con éxito');
             
           }

    })//console.log(err)

     }else{
      //filtrar por forms seleccionado
      if(this.dataSesion.logical_medical == 'cardiology'){
        let usrData;
        console.log('antes del filter', this.forms);
        console.log('idStablihsmntCardiology',this.idStablihsmntCardiology);
       let formSelect = this.forms.filter(element => element.name == this.idStablihsmntCardiology.name);
       this.userDataNcrypt.usr_data=this.idNumber.toString();
       this.userDataNcrypt.hash = localStorage.getItem('hash_stablishment');
       this.authService.ncryptUserData(this.userDataNcrypt).subscribe(res=>{
        console.log('this.authService.ncryptUserData',res.data.name);
        //Se recupera los datos encryptados y se crea el usuario
        usrData = res.data.name;
        
      console.log('FormulariosFiltrados!',formSelect);
  
      this.authForm.doc_id = usrData;
      this.authForm.name = usrData;
      this.authForm.password = this.idNumber.toString();
      this.authForm.id_customer = parseInt(localStorage.getItem('customer_id')) ;
      this.authForm.created_on = Date.now();
      this.authForm.id_stablishment = this.dataSesion.id_stablishment;
      this.authForm.clinic_history = '';
      console.log('salida this.authForm',this.authForm)
      this.userService.createUser(this.authForm).subscribe(res => {
        console.log('respuestaCreateUSer',res);
        
        this.IdUserCreate = res.resp.usr_id;// se recupera id de usuario creado
        localStorage.setItem('patientSelected',this.idNumber.toString());
        this.formToSendData.respondents_id = this.IdUserCreate;
        this.formToSendData.structure =  formSelect[0].structure ;
        this.formToSendData.nameForm =  formSelect[0].name;
        this.formToSendData.description =  formSelect[0].description;
        this.formToSendData.customer_id =  formSelect[0].customer_id;
        this.formToSendData.custf_id =  formSelect[0].customer_id;
        this.formToSendData.created_on = Date.now();
        this.formToSendData.specialist = parseInt(localStorage.getItem('id'));
        if(formSelect[0].id_stablishment  == "00020"){
          this.formToSendData.id_stablishment = "00020";
        }else{
          this.formToSendData.id_stablishment = this.dataSesion.id_stablishment;        
        }
        
        //datos para envio de mail
       
  
        
        //CrearFormulario
        this.createForm()
      })
      });
  //console.log(err)
      }else{
        let formSelect = this.forms.filter(element => element.name == this.idStablihsmntCardiology.name);

        console.log('FormulariosFiltrados!',formSelect);
    
        this.authForm.doc_id = this.idNumber.toString();
        this.authForm.name = this.idNumber.toString();
        this.authForm.password = this.idNumber.toString();
        this.authForm.id_customer = parseInt(localStorage.getItem('customer_id')) ;
        this.authForm.created_on = Date.now();
        this.authForm.id_stablishment = this.idStablihsmntCardiology.id_stablishment;
        this.authForm.clinic_history = '';
        console.log('salida',this.authForm)
        this.userService.createUser(this.authForm).subscribe(res => {
          console.log('respuestaCreateUSer',res);
          this.IdUserCreate = res.resp.usr_id;// se recupera id de usuario creado
          localStorage.setItem('patientSelected',this.idNumber.toString());
          this.formToSendData.respondents_id = this.IdUserCreate;
          this.formToSendData.structure =  formSelect[0].structure ;
          this.formToSendData.nameForm =  formSelect[0].name;
          this.formToSendData.description =  formSelect[0].description;
          this.formToSendData.customer_id =  formSelect[0].customer_id;
          this.formToSendData.custf_id =  formSelect[0].customer_id;
          this.formToSendData.created_on = Date.now();
          this.formToSendData.specialist = parseInt(localStorage.getItem('id'));
          //datos para envio de mail
         
    
          
          //CrearFormulario
          this.createForm()
        })//console.log(err)
         
    
    
        
      }

     }

   }


  getPeriodic(custf_id: number){
    if(this.customPeriodArray.hasOwnProperty('id-'+custf_id)){
      return this.customPeriodArray['id-'+custf_id];
    }
    return this.filterFormByIdStablisment.find(item => item.custf_id == custf_id).periodic[0];
  }


   AditionalForm(){
    this.authService.createForm(this.formToSendData).subscribe(resp => {
      console.log('Se a guardado el formulario con exito', parseInt(resp.resp)  )
    }
    )
   }

   addFormInUser(){
    let formSelect = this.forms.filter(element => element.name == this.idStablihsmntCardiology.name);
   
    this.formToSendData.respondents_id = this.respondentIDByConsult;
    this.formToSendData.structure =  formSelect[0].structure ;
    this.formToSendData.nameForm =  formSelect[0].name;
    this.formToSendData.description =  formSelect[0].description;
    this.formToSendData.customer_id =  formSelect[0].customer_id;
    this.formToSendData.custf_id =  formSelect[0].customer_id;
    this.formToSendData.created_on = Date.now();
    this.formToSendData.specialist = parseInt(localStorage.getItem('id'));    
    this.authService.createForm(this.formToSendData).subscribe(resp => {
      console.log('Se a guardado el formulario con exito', parseInt(resp.resp))
      //llevar a edit-respondent-form/252      
        this.router.navigate(['edit-respondent-form/'+ parseInt(resp.resp)])   
    }); 
   }

   createForms(forms: any[]){
    this.authService.createForm(forms).subscribe(resp => {
      console.log(resp);
      this.router.navigate(['edit-respondent-form/'+ parseInt(resp.resp)]);
    },err => console.log('error al crear el formulario',err)); 
   }

   createForm(){
    console.log('salida formToSendData',this.formToSendData)
    this.authService.createForm(this.formToSendData).subscribe(resp => {
      console.log('Se a guardado el formulario con exito', parseInt(resp.resp)  )
      //llevar a edit-respondent-form/252
      console.log('1');
      if(this.dataSesion.logical_medical == 'urology'){
        this.msgDone = true;
        this.sendMailCredentialsToUser();
        this.sendMailToSpecialist();
        localStorage.setItem('patientSelected',this.idNumber.toString());
        this.formToSendData.respondents_id = this.IdUserCreate;
        this.formToSendData.structure =  this.aditionalForm[0].structure ;
        this.formToSendData.nameForm =  this.aditionalForm[0].name;
        this.formToSendData.description =  this.aditionalForm[0].description;
        this.formToSendData.customer_id =  this.aditionalForm[0].customer_id;
        this.formToSendData.custf_id =  this.aditionalForm[0].customer_id;
        this.formToSendData.created_on = Date.now();
        this.formToSendData.specialist = parseInt(localStorage.getItem('id'));
        this.formToSendData.status = 'onhold/onhold';
        this.formToSendData.answered_check = true;
        this.AditionalForm();       
      }else{
        this.router.navigate(['edit-respondent-form/'+ parseInt(resp.resp)])
      }
    },err => console.log('error al crear el formulario',err));   
  }

   sendMailToSpecialist(){
     this.meetingService.sendMailToDoctor(this.mailSender).subscribe(res=>{
       console.log('sendMailTodoctor',res);
     })
   }
   sendMailCredentialsToUser(){
    this.meetingService.sendMailCredentialsToUser(this.patientMail).subscribe(res=>{
      console.log('sendMailUser',res);
    })
  }

  getDatabyIdCustomer(){
    this.customerService.getByIdCustForm(localStorage.getItem('customer_id')).subscribe(res => { // poner id de canal this.dataSesion.customerId
      console.log('Parse periodic',JSON.parse(res.resp[0].periodic))
      console.log('Customer_forms', res.resp);
      
      res.resp.sort(function(a, b) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        // names must be equal
        return 0;
      });

      if(this.dataSesion.logical_medical=='urology'){
        this.forms = res.resp;
        let filterByUnique = this.forms.filter(element=>element.id_stablishment == '00010');
        console.log('filterByUnique',filterByUnique);
        this.aditionalForm = filterByUnique;
      }else{
        if(this.dataSesion.logical_medical== 'cardiology'){   
          console.log('this.dataSesion.logical_medical', res);      
          let dataFilter = res.resp;
          this.forms = dataFilter.filter(element => element.id_stablishment == '00020' ||  element.id_stablishment == this.dataSesion.id_stablishment);   
          this.formsCardiology = dataFilter.filter(element =>  element.id_stablishment == this.dataSesion.id_stablishment);
          this.aditionalForm = dataFilter.filter(element =>  element.id_stablishment == '00020');
          console.log('getDatabyIdCustomer', this.forms)     

        }else{
          
          let filterByStablishment =  res.resp.filter(element=>element.customer_id == this.dataSesion.customerId);    
          console.log('filterByStablishment',filterByStablishment); 
          if(filterByStablishment.length == 0){          
            let filterByCero =  res.resp.filter(element=> element.id_stablishment == '0' );
            console.log('filterByCero', filterByCero);
            this.forms = filterByCero;
          }else{
            this.forms = filterByStablishment;          
          }       
        }
      }

      this.spinnerService.updateSpinnerState(false);       

    });  
    
  }

  onChange($event){
    console.log('$vent',$event)
  }

  Generar(){

  }

  chooseStablishment(data){
    console.log('forms ',this.forms);
    let filtered = this.forms.filter(element=>element.id_stablishment == this.selected);
    // filtered.periodic = JSON.parse(this.periodic);
    this.filterFormByIdStablisment = filtered.map(form => {
      return { 
        custf_id: form.custf_id,
        customer_id: form.customer_id,
        description: form.description,
        form_weight: form.form_weight,
        id_stablishment: form.id_stablishment,
        name: form.name,
        periodic: JSON.parse(form.periodic),
        structure: form.structure
      }
    });
    console.log('chooseStablishment',data,this.filterFormByIdStablisment);
    if(this.periodic == 'customized') this.generateCustomForm();
  }
  
  customPeriodArray: any = {};

  generateCustomForm(){
    this.customPeriodArray = {};
    console.log('generado');
    for(let i = 0; i < this.filterFormByIdStablisment.length; i++){
      let custf_id = this.filterFormByIdStablisment[i].custf_id;
      if(this.filterFormByIdStablisment[i].periodic[0].validatedPeriod){
        let periodic = this.filterFormByIdStablisment[i].periodic[0];
        this.customPeriodArray[`id-${custf_id}`] = {};
        Object.assign(this.customPeriodArray[`id-${custf_id}`],periodic);
        this.customPeriodArray[`id-${custf_id}`].period = this.customPeriodArray[`id-${custf_id}`].period.map(value => ({amount: value, unit: 'hours'}));
        console.log(this.customPeriodArray[`id-${custf_id}`].period);
        // for(let j = 0; j < this.filterFormByIdStablisment[i].periodic[0].period.length; j++){
        // }
      }
    }
    console.log(this.customPeriodArray);
  }

  addPeriod(custf_id: number){
    this.customPeriodArray['id-'+custf_id].period.push(
      {
        amount: 0,
        unit: 'hours'
      }
    );
  }

  deletePeriod(i: number,custf_id: number){
    if(this.customPeriodArray['id-'+custf_id].period.length < 3){
      alert('Necesita establecer al menos 2 periodos en los que se generará el formulario.');
    }else{
      this.customPeriodArray['id-'+custf_id].period.splice(i,1);
      console.log(this.customPeriodArray);
    }
  }

  ngOnDestroy(): void {
    this.userService.setOnCreatePatient(false);
  }

  periodicLevel = {
    normal: 0,
    grave: 1,
    customized: 0
  }

  codes =[
    {"code_country":"AF","code":"93"},
    {"code_country":"AD","code":"376"},
    {"code_country":"AE","code":"971"},
    {"code_country":"AG","code":"1268"},
    {"code_country":"AI","code":"1 264"},
    {"code_country":"AL","code":"355"},
    {"code_country":"AM","code":"374"},
    {"code_country":"AO","code":"244"},
    {"code_country":"AQ","code":""},
    {"code_country":"AR","code":"54"},
    {"code_country":"AS","code":"1 684"},
    {"code_country":"AT","code":"43"},
    {"code_country":"AU","code":"61"},
    {"code_country":"AW","code":"297"},
    {"code_country":"AX","code":""},
    {"code_country":"AZ","code":"994"},
    {"code_country":"BA","code":"387"},
    {"code_country":"BB","code":"1 246"},
    {"code_country":"BD","code":"880"},
    {"code_country":"BE","code":"32"},
    {"code_country":"BF","code":"226"},
    {"code_country":"BG","code":"359"},
    {"code_country":"BH","code":"973"},
    {"code_country":"BI","code":"257"},
    {"code_country":"BJ","code":"229"},
    {"code_country":"BL","code":"590"},
    {"code_country":"BM","code":"1 441"},
    {"code_country":"BN","code":"673"},
    {"code_country":"BO","code":"591"},
    {"code_country":"BR","code":"55"},
    {"code_country":"BS","code":"1 242"},
    {"code_country":"BT","code":"975"},
    {"code_country":"BW","code":"267"},
    {"code_country":"BY","code":"375"},
    {"code_country":"BZ","code":"501"},
    {"code_country":"CA","code":"1"},
    {"code_country":"CC","code":"61"},
    {"code_country":"CD","code":"243"},
    {"code_country":"CF","code":"236"},
    {"code_country":"CG","code":"242"},
    {"code_country":"CH","code":"41"},
    {"code_country":"CI","code":"225"},
    {"code_country":"CK","code":"682"},
    {"code_country":"CL","code":"56"},
    {"code_country":"CM","code":"237"},
    {"code_country":"CN","code":"86"},
    {"code_country":"CO","code":"57"},
    {"code_country":"CR","code":"506"},
    {"code_country":"CU","code":"53"},
    {"code_country":"CV","code":"238"},
    {"code_country":"CX","code":"61"},
    {"code_country":"CY","code":"537"},
    {"code_country":"CZ","code":"420"},
    {"code_country":"DE","code":"49"},
    {"code_country":"DJ","code":"253"},
    {"code_country":"DK","code":"45"},
    {"code_country":"DM","code":"1 767"},
    {"code_country":"DO","code":"1 849"},
    {"code_country":"DZ","code":"213"},
    {"code_country":"EC","code":"593"},
    {"code_country":"EE","code":"372"},
    {"code_country":"EG","code":"20"},
    {"code_country":"ER","code":"291"},
    {"code_country":"ES","code":"34"},
    {"code_country":"ET","code":"251"},
    {"code_country":"FI","code":"358"},
    {"code_country":"FJ","code":"679"},
    {"code_country":"FK","code":"500"},
    {"code_country":"FM","code":"691"},
    {"code_country":"FO","code":"298"},
    {"code_country":"FR","code":"33"},
    {"code_country":"GA","code":"241"},
    {"code_country":"GB","code":"44"},
    {"code_country":"GD","code":"1 473"},
    {"code_country":"GE","code":"995"},
    {"code_country":"GF","code":"594"},
    {"code_country":"GG","code":"44"},
    {"code_country":"GH","code":"233"},
    {"code_country":"GI","code":"350"},
    {"code_country":"GL","code":"299"},
    {"code_country":"GM","code":"220"},
    {"code_country":"GN","code":"224"},
    {"code_country":"GP","code":"590"},
    {"code_country":"GQ","code":"240"},
    {"code_country":"GR","code":"30"},
    {"code_country":"GS","code":"500"},
    {"code_country":"GT","code":"502"},
    {"code_country":"GU","code":"1 671"},
    {"code_country":"GW","code":"245"},
    {"code_country":"GY","code":"595"},
    {"code_country":"HK","code":"852"},
    {"code_country":"HN","code":"504"},
    {"code_country":"HR","code":"385"},
    {"code_country":"HT","code":"509"},
    {"code_country":"HU","code":"36"},
    {"code_country":"ID","code":"62"},
    {"code_country":"IE","code":"353"},
    {"code_country":"IL","code":"972"},
    {"code_country":"IL","code":"972"},
    {"code_country":"IM","code":"44"},
    {"code_country":"IN","code":"91"},
    {"code_country":"IO","code":"246"},
    {"code_country":"IQ","code":"964"},
    {"code_country":"IR","code":"98"},
    {"code_country":"IS","code":"354"},
    {"code_country":"IT","code":"39"},
    {"code_country":"JE","code":"44"},
    {"code_country":"JM","code":"1 876"},
    {"code_country":"JO","code":"962"},
    {"code_country":"JP","code":"81"},
    {"code_country":"KE","code":"254"},
    {"code_country":"KG","code":"996"},
    {"code_country":"KH","code":"855"},
    {"code_country":"KI","code":"686"},
    {"code_country":"KM","code":"269"},
    {"code_country":"KN","code":"1 869"},
    {"code_country":"KP","code":"850"},
    {"code_country":"KR","code":"82"},
    {"code_country":"KW","code":"965"},
    {"code_country":"KY","code":" 345"},
    {"code_country":"KZ","code":"7 7"},
    {"code_country":"LA","code":"856"},
    {"code_country":"LB","code":"961"},
    {"code_country":"LC","code":"1 758"},
    {"code_country":"LI","code":"423"},
    {"code_country":"LK","code":"94"},
    {"code_country":"LR","code":"231"},
    {"code_country":"LS","code":"266"},
    {"code_country":"LT","code":"370"},
    {"code_country":"LU","code":"352"},
    {"code_country":"LV","code":"371"},
    {"code_country":"LY","code":"218"},
    {"code_country":"MA","code":"212"},
    {"code_country":"MC","code":"377"},
    {"code_country":"MD","code":"373"},
    {"code_country":"ME","code":"382"},
    {"code_country":"MF","code":"590"},
    {"code_country":"MG","code":"261"},
    {"code_country":"MH","code":"692"},
    {"code_country":"MK","code":"389"},
    {"code_country":"ML","code":"223"},
    {"code_country":"MM","code":"95"},
    {"code_country":"MN","code":"976"},
    {"code_country":"MO","code":"853"},
    {"code_country":"MP","code":"1 670"},
    {"code_country":"MQ","code":"596"},
    {"code_country":"MR","code":"222"},
    {"code_country":"MS","code":"1664"},
    {"code_country":"MT","code":"356"},
    {"code_country":"MU","code":"230"},
    {"code_country":"MV","code":"960"},
    {"code_country":"MW","code":"265"},
    {"code_country":"MX","code":"52"},
    {"code_country":"MY","code":"60"},
    {"code_country":"MZ","code":"258"},
    {"code_country":"NA","code":"264"},
    {"code_country":"NC","code":"687"},
    {"code_country":"NE","code":"227"},
    {"code_country":"NF","code":"672"},
    {"code_country":"NG","code":"234"},
    {"code_country":"NI","code":"505"},
    {"code_country":"NL","code":"31"},
    {"code_country":"NO","code":"47"},
    {"code_country":"NP","code":"977"},
    {"code_country":"NR","code":"674"},
    {"code_country":"NU","code":"683"},
    {"code_country":"NZ","code":"64"},
    {"code_country":"OM","code":"968"},
    {"code_country":"PA","code":"507"},
    {"code_country":"PE","code":"51"},
    {"code_country":"PF","code":"689"},
    {"code_country":"PG","code":"675"},
    {"code_country":"PH","code":"63"},
    {"code_country":"PK","code":"92"},
    {"code_country":"PL","code":"48"},
    {"code_country":"PM","code":"508"},
    {"code_country":"PN","code":"872"},
    {"code_country":"PR","code":"1 939"},
    {"code_country":"PS","code":"970"},
    {"code_country":"PT","code":"351"},
    {"code_country":"PW","code":"680"},
    {"code_country":"PY","code":"595"},
    {"code_country":"QA","code":"974"},
    {"code_country":"RE","code":"262"},
    {"code_country":"RO","code":"40"},
    {"code_country":"RS","code":"381"},
    {"code_country":"RU","code":"7"},
    {"code_country":"RW","code":"250"},
    {"code_country":"SA","code":"966"},
    {"code_country":"SB","code":"677"},
    {"code_country":"SC","code":"248"},
    {"code_country":"SD","code":"249"},
    {"code_country":"SE","code":"46"},
    {"code_country":"SG","code":"65"},
    {"code_country":"SH","code":"290"},
    {"code_country":"SI","code":"386"},
    {"code_country":"SJ","code":"47"},
    {"code_country":"SK","code":"421"},
    {"code_country":"SL","code":"232"},
    {"code_country":"SM","code":"378"},
    {"code_country":"SN","code":"221"},
    {"code_country":"SO","code":"252"},
    {"code_country":"SR","code":"597"},
    {"code_country":"ST","code":"239"},
    {"code_country":"SV","code":"503"},
    {"code_country":"SY","code":"963"},
    {"code_country":"SZ","code":"268"},
    {"code_country":"TC","code":"1 649"},
    {"code_country":"TD","code":"235"},
    {"code_country":"TG","code":"228"},
    {"code_country":"TH","code":"66"},
    {"code_country":"TJ","code":"992"},
    {"code_country":"TK","code":"690"},
    {"code_country":"TL","code":"670"},
    {"code_country":"TM","code":"993"},
    {"code_country":"TN","code":"216"},
    {"code_country":"TO","code":"676"},
    {"code_country":"TR","code":"90"},
    {"code_country":"TT","code":"1 868"},
    {"code_country":"TV","code":"688"},
    {"code_country":"TW","code":"886"},
    {"code_country":"TZ","code":"255"},
    {"code_country":"UA","code":"380"},
    {"code_country":"UG","code":"256"},
    {"code_country":"US","code":"1"},
    {"code_country":"UY","code":"598"},
    {"code_country":"UZ","code":"998"},
    {"code_country":"VA","code":"379"},
    {"code_country":"VC","code":"1 784"},
    {"code_country":"VE","code":"58"},
    {"code_country":"VG","code":"1 284"},
    {"code_country":"VI","code":"1 340"},
    {"code_country":"VN","code":"84"},
    {"code_country":"VU","code":"678"},
    {"code_country":"WF","code":"681"},
    {"code_country":"WS","code":"685"},
    {"code_country":"YE","code":"967"},
    {"code_country":"YT","code":"262"},
    {"code_country":"ZA","code":"27"},
    {"code_country":"ZM","code":"260"},
    {"code_country":"ZW","code":"263"},    
]

}
