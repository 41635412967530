export const dataConfig = {
  srvMiddl: {
      host: 'api-bck-001.e-processmed.com',
      port: 13000
  },
  srvMiddlTest: {
    host: '192.210.232.242',
    port: 3000
  },
  srvMiddlDev: {
    host: 'localhost',
    port: 3000
  },
  filePro:{
    url:'https://api-bck-001.e-processmed.com:17400'
  },
  fileMiddle:{
    url:'http://api-test-001.eprocessmed.work:7400'
  },
  fileDev:{
    url:'http://167.86.67.253:7400'
  },
  http: 'http://',
  https: 'https://',
  environment: 'Production', // Development || Testing || Production
  ssl: true  // false || true 
};
//api.saas-ecuador.comz
//
    
