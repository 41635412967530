import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { globalData } from '../global/global_data';

@Injectable({
  providedIn: 'root'
})
export class FomrsService {

  value;

  constructor(private http: HttpClient) { }

  getForms(): Observable<any> {
    //console.log(`${globalData.urls.rootURI}${globalData.urls.frm}`);
    return this.http.get(`${globalData.urls.rootURI}${globalData.urls.frm}`);
  }

  getByIdForms(id): Observable<any> {
    return this.http.get(`${globalData.urls.rootURI}${globalData.urls.getFrm}/${id}`);
  }

  createForm(data): Observable<any> {// cambiar rustas a body
    const body: any = JSON.stringify(data);
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${globalData.urls.rootURI}${globalData.urls.crtFrm}`, body, {headers: headers});
  }

  // update generic
  // udate status
  // delete

   ValueId(data){
    this.value = data;
  }

  getFormula(data){    
    return this.http.get(`${globalData.urls.rootURI}${globalData.urls.formulas}/${data}`);
  }

}
