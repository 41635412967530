<app-modal-message [titleMessage]="titleMessage" [message]="message" (CloseMdlMessage)="closeMdlMessage()"></app-modal-message>
<mat-toolbar style="background: #00669A;">
    <div class="pointer" style="color: #00669A; background: rgb(255, 255, 255); border-radius: 50mm; width: 3%;" [routerLink]="['/']">
        <span style="margin-left: 20%; margin-top: 20%;" routerLinkActive="router-link-active" class="material-icons">
      keyboard_backspace
      </span>
    </div>
    <div class="pointer" style="background: #00669A;" [routerLink]="['/']" style="margin-left: 1%; color: rgb(255, 255, 255);">
        Regresar al tablero médico.
    </div>

</mat-toolbar>
<div class="container-fluid">
    <div class="container-fluid">

        <div class="row">
            
            <div *ngIf="dataSesion.logical_medical=='urology'" class="card col-sm-12" >
                <div >
                   
                    <label  for="diagnosis" style="padding-top: 20px;">Puntuación total IPSS:</label>   
                    <br>     
                     <label *ngIf="resultWeight!=''&& resultWeight != 'NaN-undefined'" style="font-size: 40px;"> {{resultWeight}}</label>
                     <label *ngIf="resultWeight=='' || resultWeight == 'NaN-undefined'" style="font-size: 18px;"> No existe información del formulario IPSS</label>
                </div>
            </div>

            <div class="card col-sm-12" >

                <div class="overflow-auto card-body">
                    <p class="titel_form">{{respondentForm.name}}</p>

                    <form class="respondent_form" id="editRespondentForm" #editRespondentForm="ngForm" (ngSubmit)="onSubmit()">

                        <section *ngFor="let segment of structure; let i = index;">

                            <div *ngFor="let page of segment.pages; let j = index;">

                                <div *ngIf="i == 0 && j == 0; then firstDisplay else followingDisplay"></div>

                                <ng-template #firstDisplay>
                                    <div class="tab tab0">
                                        <p class="desc_form">{{respondentForm.description}}</p>
                                        <h3 class="titel_seg" *ngIf="j == 0;">{{segment.name}}</h3>
                                        <ul>
                                            <li class="li_question" *ngFor="let question of page;">

                                                <ul [ngSwitch]="question.type">
                                                    <li *ngSwitchCase="'Text'">
                                                        <app-text-number [dtaQuestions]="question" [index]="j"></app-text-number>
                                                    </li>
                                                    <li *ngSwitchCase="'Number'">
                                                        <app-text-number [dtaQuestions]="question"></app-text-number>
                                                    </li>
                                                    <li *ngSwitchCase="'Textarea'">
                                                        <app-textarea [dtaQuestions]="question"></app-textarea>
                                                    </li>
                                                    <li *ngSwitchCase="'Radio'">
                                                        <app-radio [dtaQuestions]="question"></app-radio>
                                                    </li>
                                                    <li *ngSwitchCase="'Checkbox'">
                                                        <!-- <app-checkbox [dtaQuestions]="question"></app-checkbox> -->
                                                        <div class="">

                                                            <label id="lbl-{{question.name}}" class="lbl lbl-question" >{{question.name}}</label>
                                                          
                                                              <label class="container" *ngFor="let item of question.items;">
                                                                <label class="form-check-label check_label" for="{{question.name}}">{{item}}</label>
                                                                <input type="checkbox" class="presenter" name="{{question.name}}" id="{{question.name}}-{{item}}" value="{{item}}">
                                                                <span class="checkmark"></span>
                                                              </label>
                                                          
                                                          
                                                          </div>
                                                    </li>
                                                    <li *ngSwitchCase="'Combobox'">
                                                        <app-combobox [dtaQuestions]="question"></app-combobox>
                                                    </li>
                                                    <li *ngSwitchCase="'Email'">
                                                        <app-email [dtaQuestions]="question"></app-email>
                                                    </li>
                                                    <li *ngSwitchCase="'File'">
                                                        <label style="color: rgb(119, 118, 118);">
                                                            {{question.name}}                                                        
                                                        </label>
                                                        <!-- <iframe id="pdfViewer" frameborder="0" width="800"></iframe> -->
                                                          <button class="btn " style="background-color: #00669A; color: #fff;" type="button" 
                                                           (click)="open(content,question.answers)">ver pdf</button>                                              
                                                    </li>
                                                    <li *ngSwitchCase="'Tel'">
                                                        <app-tel [dtaQuestions]="question"></app-tel>
                                                    </li>
                                                    <li *ngSwitchCase="'Datepicker'">
                                                        <label id="lbl-{{question.name}}" class="lbl lbl-question"for="{{question.name}}">{{question.name}}</label>
                                                        <input placeholder="{{question.description}}" class="textbox-n" type="text" onfocus="(this.type='date')" onblur="(this.type='text')" name="date" id='date' [(ngModel)]="date">                                                 
                                                    </li>
                                                    <li *ngSwitchCase="'Image'">
                                                        <app-image [dtaQuestions]="question"></app-image>
                                                    </li>
                                                    <li *ngSwitchCase="'Radio-complement'">
                                                        <app-radio-complement [dtaQuestions]="question"></app-radio-complement>
                                                    </li>
                                                    <li *ngSwitchCase="'Checkbox-complement'">
                                                        <app-checkbox-complement [dtaQuestions]="question"></app-checkbox-complement>
                                                    </li>
                                                    <li *ngSwitchCase="'Subtitle'" style="margin-top: 5%;">
                                                        <h1 style="color: #00669A;">{{question.name}} </h1>
                                                    </li>

                                                </ul>

                                            </li>

                                        </ul>

                                    </div>

                                </ng-template>

                                <ng-template #followingDisplay>
                                    <div class="tab">
                                        <h3 class="titel_seg" *ngIf="j == 0;">{{segment.name}}</h3>

                                        <ul>
                                            <li class="li_question" *ngFor="let question of page;">

                                                <ul [ngSwitch]="question.type">
                                                    <li *ngSwitchCase="'Text'">
                                                        <app-text-number [dtaQuestions]="question"></app-text-number>
                                                    </li>
                                                    <li *ngSwitchCase="'Number'">
                                                        <app-text-number [dtaQuestions]="question"></app-text-number>
                                                    </li>
                                                    <li *ngSwitchCase="'Textarea'">
                                                        <app-textarea [dtaQuestions]="question"></app-textarea>
                                                    </li>
                                                    <li *ngSwitchCase="'Radio'">
                                                        <app-radio [dtaQuestions]="question"></app-radio>
                                                    </li>
                                                    <li *ngSwitchCase="'Checkbox'">
                                                        <app-checkbox [dtaQuestions]="question"></app-checkbox>                                                 
                                                    </li>
                                                    <li *ngSwitchCase="'Combobox'">
                                                        <app-combobox [dtaQuestions]="question"></app-combobox>
                                                    </li>
                                                    <li *ngSwitchCase="'Email'">
                                                        <app-email [dtaQuestions]="question"></app-email>
                                                    </li>
                                                    <li *ngSwitchCase="'File'">
                                                        <!-- <pdf-viewer src="https://vadimdez.github.io/ng2-pdf-viewer/pdf-test.pdf"></pdf-viewer>
                                                      
                                                        <app-file [dtaQuestions]="question"></app-file> -->
                                                        <label style="color: rgb(119, 118, 118);">
                                                            {{question.name}}                                                        
                                                        </label>
                                                        <!-- <iframe id="pdfViewer" frameborder="0" width="800"></iframe> -->
                                                          <button class="btn " style="background-color: #00669A; color: #fff;" type="button"
                                                            (click)="open(content,question.answers)">ver pdf</button>
                                                    </li>
                                                    <li *ngSwitchCase="'Tel'">
                                                        <app-tel [dtaQuestions]="question"></app-tel>
                                                    </li>
                                                    <li *ngSwitchCase="'Datepicker'">
                                                        <!-- <app-date [dtaQuestions]="question"></app-date> -->
                                                        <div class="">
                                                            <label id="lbl-{{question.name}}" class="lbl lbl-question"for="{{question.name}}">{{question.name}}</label>
                                                            <input matInput [matDatepicker]="picker" type="{{question.type}}" [min]="minDate" [max]="maxDate" class="presenter"
                                                                   id="{{question.name}}" name="{{question.name}}" value="{{question.answers}}" placeholder="{{question.answers}}"
                                                                  (dateChange)=" SeleccionDeDataPicker($event)" class="textbox-n" type="text">
                                                            <mat-datepicker-toggle  style="position: absolute; margin: -5%; margin-top: -0.5%;" matSuffix [for]="picker" ></mat-datepicker-toggle>
                                                            <mat-datepicker   #picker></mat-datepicker>
                                                          </div>                                                      
                                                    </li>
                                                    <li *ngSwitchCase="'Image'">
                                                        <app-image [dtaQuestions]="question"></app-image>
                                                    </li>
                                                    <li *ngSwitchCase="'Radio-complement'">
                                                        <app-radio-complement [dtaQuestions]="question"></app-radio-complement>
                                                    </li>
                                                    <li *ngSwitchCase="'Checkbox-complement'">
                                                        <app-checkbox-complement [dtaQuestions]="question"></app-checkbox-complement>
                                                    </li>
                                                    <li *ngSwitchCase="'Subtitle'" style="margin-top: 5%;">
                                                        <h1 style="color: #00669A;">{{question.name}} </h1>
                                                    </li>
                                                </ul>

                                            </li>

                                        </ul>

                                    </div>
                                </ng-template>

                            </div>

                        </section>

                        <div style="text-align:center;margin-top:40px;">
                            <span class="step" *ngFor="let pag of pages;"></span>
                        </div>

                    </form>
                </div>

            </div>
<!-- 
            <div class="card col-sm-12" >
                <div>       
                    <label *ngIf="dataSesion.logical_medical=='urology'" for="diagnosis" style="padding-top: 20px;" >Comentarios</label>      
                    <textarea [(ngModel)]="comentarios" name="comentarios" placeholder="{{comentarios}}" matInput  rows="8" ></textarea>
                </div>
              </div> -->


            <div class="card col-sm-12" >
                <div >                   
                    <label for="diagnosis" style="padding-top: 20px;" >Observaciones</label>
                    <textarea *ngIf="respondentForm.diagnosis!='null'"   id="diagnosisRespond" rows="8">{{respondentForm.diagnosis}}</textarea>
                    <textarea *ngIf="respondentForm.diagnosis=='null'" matInput [(ngModel)]="diagnosis" id="diagnosisRespond" rows="8" ></textarea>
                </div>
            </div>

            <div class="card col-sm-12" *ngIf="dataSesion.logical_medical!='urology'">
                <div class="form-group">
                    <label for="presumption" style="padding-top: 20px;">Presunción diagnóstica:</label>
                    <select placeholder="Seleccione una opción" class="form-control" id="presumption" name="presumption" [(ngModel)]="select">
              <option value="No COVID-19">No COVID-19</option>
              <option value="Leve, se indica asistencia domiciliaria">Leve, se indica asistencia domiciliaria</option>
              <option value="Moderado, se indica asistencia por su Médico de Atención Primaria">Moderado, se indica asistencia por su Médico de Atención Primaria</option>
              <option value="Severa, se indica acudir a Urgencias">Severa, se indica acudir a Urgencias</option>
            </select>
                    <div *ngIf="warnin">
                        <label style="color: red;"> Eliga una opcion*</label>
                    </div>
                </div>
            </div>

            <!-- <div class="card col-sm-12" *ngIf="dataSesion.logical_medical=='urology'">
              
                <div class="">
                    <label id="lbl" class="lbl lbl-question">Grabación Flujometrías Sonoras</label>
                    <label class="container">
                      <label  class="form-check-label check_label">Si</label>
                      <input class="presenter" type="radio" name="Si" id="Si" value="Si">
                      
                      <span class="checkmark"></span>
                    </label>
                    <label class="container">
                        <label  class="form-check-label check_label">No</label>
                        <input class="presenter" type="radio"  name="Si" id="Si" value="No">
                        <span class="checkmark"></span>
                    </label>
                  </div>
                  
            </div> -->

            <!-- <div class="card col-sm-12" *ngIf="dataSesion.logical_medical=='urology'">
                <div class="form-group">
                    <label for="presumption" style="padding-top: 20px;">Presunción diagnóstica:</label>
                    <select placeholder="Seleccione una opción" class="form-control" id="presumption" name="presumption" [(ngModel)]="select">
       
              <option value="Normal">Normal</option>
              <option value="Fluctuante">Fluctuante</option>
              <option value="Interrumpido">Interrumpido</option>
              <option value="Meseta">Meseta</option>
            </select>
                    <div *ngIf="warnin">
                        <label style="color: red;"> Eliga una opcion*</label>
                    </div>
                </div>
            </div> -->


            

            <div>
                <!-- <button class="btn btn-info" type="button" id="btn_editDiag" (click)="onSubmit()" disabled>Editar</button> -->
                <button class="btn btn-success" type="button" id="btn_saveDiag" (click)="onSubmit()">Guardar</button>
            </div>

        </div>

    </div>
</div>

<ng-template #content let-modal class="modal" >
    <div class="modal-header" >  
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Cerrar')">Cerrar</button>

    </div>
    <div >
      <pdf-viewer
      src="{{urlPDF}}"
      [rotation]="0"
        [fit-to-page]="true"
        [original-size]="true"
        [show-all]="true"
        [zoom]="1.5"
        style="display: block;"
    ></pdf-viewer>
    </div>
    <div class="modal-footer" style="margin-top: 100%;">
     
    </div>
  </ng-template>