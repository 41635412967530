
<button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>

  <div *ngIf="dataSesion.logical_medical != 'urology' && dataSesion.logical_medical != 'preconsult' ">
    <p class="text-center" style="color: #5288ec; font-size: 14px;">Ingresar paciente</p>  
    <div *ngIf="idNumber==''" class="bg-danger p-10 text-white"> Ingrese el número de identificación del Usuario</div>
  
    <div class="form__group field">
      <label for="usr" class="form__label">{{title_text}}</label>
      <input *ngIf="channel != 2" type="text" [(ngModel)]="idNumber" class="form__field" placeholder="Ingresar número de cédula" name="usr" id='usr'  required />
      <input *ngIf="channel == 2" type="number" [(ngModel)]="idNumber" class="form__field" placeholder="Ingresar Número de Paciente" name="usr" id='usr'  required />    
    </div>

    <div class="form__group field"  *ngIf="dataSesion.logical_medical!='cardiology'">
      <label for="usr" class="form__label">Crear formulario </label>
      <mat-form-field appearance="fill">
        <mat-label>Formularios</mat-label>
        <mat-select [(ngModel)]="idStablihsmntCardiology">
          <mat-option  *ngFor="let item of forms" [value]="item">
            {{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" *ngIf="dataSesion.logical_medical!='cardiology' && dataSesion.logical_medical != 'preconsult'">
      <button mat-raised-button style="background: #00669A; color: #fff; " class="btn-block btn-lg m-t-20 m-b-20" type="button" (click)="  verifyUserUca()">Enviar</button>
  </div>


    <div *ngIf="dataSesion.logical_medical=='cardiology'">
      <div class="form__group field"  *ngIf="createFormFrame == 1" >
        <label for="usr" class="form__label">Crear formulario</label>
        <mat-form-field appearance="fill">
          <mat-label>Formularios</mat-label>
          <mat-select [(ngModel)]="idStablihsmntCardiology">
            <mat-option  *ngFor="let item of formsCardiology" [value]="item">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>   
      </div>
  
      <div class="form__group field"  *ngIf="createFormFrame == 2" >
        <label for="usr" class="form__label">Crear formulario</label>
        <mat-form-field appearance="fill">
          <mat-label>Formularios</mat-label>
          <mat-select [(ngModel)]="idStablihsmntCardiology">
            <mat-option  *ngFor="let item of aditionalForm" [value]="item">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>   
      </div>
    </div>
  

  
    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" *ngIf="dataSesion.logical_medical=='cardiology'">
        <button  *ngIf="frame == 0 && generalBoolBTN" mat-raised-button style="background: #00669A; color: #fff; " class="btn-block btn-lg m-t-20 m-b-20" type="button" (click)="verifyUserCardiology(1)">Seleccionar formulario</button>
        <button  *ngIf="frame == 1 && generalBoolBTN" mat-raised-button style="background: #00669A; color: #fff; " class="btn-block btn-lg m-t-20 m-b-20" type="button" (click)="verifyUserCardiology(2)">Enviar</button>
        <button  *ngIf="frame == 2 && generalBoolBTN" mat-raised-button style="background: #00669A; color: #fff; " class="btn-block btn-lg m-t-20 m-b-20" type="button" (click)="verifyUserCardiology(3)">Crear</button>

        
    </div>

    <div *ngIf="createFormFrame ==3">
      <h2>Procesando...</h2>
    </div>
    
  </div>

  <div *ngIf="dataSesion.logical_medical == 'urology' ||  dataSesion.logical_medical == 'preconsult'" >
    <p class="text-center" style="color: #00669A; font-size: 20px;"> <b>Ingresar paciente</b> </p>
    <div class="row">
      <label for="example-datetime-local-input" class="col-4 col-form-label">Centro Médico</label>
      <div class="col-8">
        <div class="form__group field" style="background: rgb(201, 227, 235); padding: 2%; border-radius: 2mm;">
          <mat-select [disabled]="disabledBTN" [(ngModel)]="selected">
            <mat-option *ngFor="let item of clinicsData" [value]="item.code" (click)="chooseStablishment(item.name)">
              {{item.name}}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <label style="margin-top: 5%;" for="example-datetime-local-input" class="col-4 col-form-label">Número de paciente</label>
      <div style="margin-top: 5%;" class="col-8">
        <div class="form__group field" >
          <input type="number" [disabled]="disabledBTN" [(ngModel)]="idNumber">
        </div>
      </div>

      <label  for="example-datetime-local-input" class="col-4 col-form-label">Número de historia clínica</label>
      <div class="col-8">
        <div class="form__group field">
          <input type="text" [disabled]="disabledBTN" [(ngModel)]="clinicHistoryText">
        </div>
      </div>

      <label  for="example-datetime-local-input" class="col-4 col-form-label mb-3">Correo</label>
      <div class="col-8">
        <div class="form__group field">
          <input type="email" required [formControl]="emailControl" [disabled]="disabledBTN" [(ngModel)]="mailUserSender">
          <span *ngIf="emailControl.errors?.required && !emailControl.pristine"><small class="error-tag">Campo requerido</small></span>
          <span *ngIf="emailControl.errors?.email"><small class="error-tag">Por favor ingrese un email válido</small></span>
        </div>
      </div>
    </div>  
    <!-- <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
        <button mat-raised-button style="background: #00669A; color: #fff; " class="btn-block btn-lg m-t-20 m-b-20" type="button" (click)="createUSer()">Grabar</button>
    </div> -->
    <div *ngIf="!msgDone" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
      <button *ngIf="btnCreate" mat-raised-button style="background: #00669A; color: #fff; " class="btn-block btn-lg m-t-20 m-b-20" type="button" (click)="verifyUser()" [disabled]="!emailControl.valid">Continuar</button>
    </div>


    <div *ngIf="msgDone" class="card-subtitle" style=" background: rgb(204, 223, 247); border-radius: 2mm; padding: 2%; margin-top: 5%;">
      <div class="footer">
        <p class="text-center" style="color: #5288ec; font-size: 14px;">!Atención!</p>      
      </div>
      Usuario creado satisfactoriamente, las credenciales de acceso fueron enviadas a : {{dataSesion.mail}} y {{mailUserSender}}
    </div>
    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" style="margin-top: 5%;" *ngIf="msgDone">
      <button mat-raised-button style="background: #4EAAD8; color: #fff; " class="btn-block btn-lg m-t-20 m-b-20" type="button" (click)="closeModal()">Cerrar</button>
   </div>
    
  </div>

  